import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import somethingWentWrongUrl from '../assets/image/SomethingWentWrong.png';
import { GridWithHeight } from './common/components/layout';
import { rootStore } from './common/store/root-store';
import { defaultTheme } from './common/theme/default-theme';

const GridContainer = styled(Grid)`
  height: 95vh;
  align-content: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  background-color: #00a599;
  &:hover {
    background-color: rgba(27, 113, 107);
  }
`;

const ErrorReference = styled.span`
  color: ${defaultTheme.color.primary};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  text-align: center;
`;

export default function FallbackComponent() {
  const reset = () => {
    rootStore.masterReset();
  };

  const redirectToHome = () => {
    window.location.pathname = '/';
  };

  useEffect(() => {
    return reset;
  }, []);

  const { dashboardWithErrorName, errorReference } = rootStore.errorStore;
  const { t } = useTranslation();

  const setClipboardToErrorRef = () => {
    const type = 'text/plain';
    const blob = new Blob([errorReference], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data);
  };

  return (
    <GridContainer container justifyContent="center">
      <GridWithHeight item container xs={12} height={'60vh'} justifyContent="center" alignContent="center">
        <Grid item xs={5} justifyContent="center" alignContent="center" maxHeight={'60vh'}>
          <img src={somethingWentWrongUrl} width={'100%'} height={'100%'} />
        </Grid>
      </GridWithHeight>
      <GridWithHeight justifyContent="center" height={'5vh'} item container xs={12}>
        <Typography variant="h5" style={{ color: 'palevioletred' }}>
          <Trans i18nKey="pages:errorBoundary.caution" />
        </Typography>
      </GridWithHeight>
      <MessageContainer>
        {dashboardWithErrorName ? (
          <>
            <Typography variant="h5">
              {t('pages:errorBoundary.dashboard')} {t(dashboardWithErrorName)} {t('pages:errorBoundary.couldNotLoad')}
            </Typography>
            <Typography variant="h5">
              {t('pages:errorBoundary.pleaseTryToReload')} <ErrorReference>{errorReference}</ErrorReference>
              <ContentCopyIcon onClick={setClipboardToErrorRef} color="primary" style={{ cursor: 'pointer' }} />{' '}
              {t('pages:errorBoundary.ifThisHappensAgain')}
            </Typography>
          </>
        ) : (
          <Typography variant="h5">
            <Trans i18nKey="pages:errorBoundary.message" />
          </Typography>
        )}
      </MessageContainer>
      <GridWithHeight justifyContent="center" height={'5vh'} item container xs={12}>
        <StyledButton variant="contained" color="primary" onClick={redirectToHome}>
          <Trans i18nKey="pages:errorBoundary.goToHome" />
        </StyledButton>
      </GridWithHeight>
      <Button />
    </GridContainer>
  );
}
