import { Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import styled from 'styled-components';
import timeoutImg from '../../../assets/image/timeout.png';
import { Spacer } from '../../common/components/layout';
import { rootStore } from '../../common/store/root-store';

const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 5px;
  white-space: pre-line;
`;

const StyledButton = styled(Button)`
  border-radius: 30px;
  margin-bottom: 1em;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 5%;
`;

const CountdownTypography = styled(Typography)`
  font-weight: 800;
  font-size: 1.5em;
`;

const Blur = styled.div`
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  z-index: 1200;
  pointer-events: none;
`;

export const IdleTimerGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  return (
    <>
      <LogoutModal />
      {children}
    </>
  );
});

const LogoutModal = observer(() => {
  const timeout = (rootStore.companySettingsStore.autoLogoutTimeInMinutes() ?? 20) * 60000;
  const promptBeforeIdle = (rootStore.companySettingsStore.warningToLogoutTimeInMinutes() ?? 1) * 6000;

  const { isSaml } = rootStore.authStore;

  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const onIdle = () => {
    if (!isSaml) {
      //This was taken out of authStore.logOutUser to remove a cyclical dependency
      rootStore.permissionsStore.stopRoleSimulation();
      rootStore.authStore.logOutUser();
    }
    setOpen(false);
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
    if (isSaml) {
      pause();
    }
  };

  const { getRemainingTime, activate, isPrompted, pause } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    timers: workerTimers,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      requestIdleCallback(() => setRemaining(Math.ceil(getRemainingTime() / 1000)));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {isSaml && isPrompted() ? <Blur /> : null}
      <Modal open={open} onClose={activate} sx={{ width: '40vw', margin: '0 auto', top: '33%' }}>
        <StyledPaper>
          <Grid item container xs={12}>
            <Grid item xs={5} style={{ padding: 10 }}>
              <img src={timeoutImg} style={{ width: '100%' }} />
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                padding: '1.5em 1em',
              }}
            >
              <StyledTypography variant="h6">
                {<Trans i18nKey="common:snackbarMessages.userLogoutTitle" />}
              </StyledTypography>
              {!isSaml ? (
                <Grid container height="80%" alignItems="center">
                  <Typography variant="h6">
                    {<Trans i18nKey="common:snackbarMessages.sessionPausedDescription" />}
                  </Typography>
                </Grid>
              ) : (
                <>
                  <StyledTypography>
                    {<Trans i18nKey="common:snackbarMessages.userLogoutDescription" />}
                  </StyledTypography>
                  <Typography>{<Trans i18nKey="common:snackbarMessages.userLogoutWarning" />}</Typography>
                  <CountdownTypography>
                    {t('common:snackbarMessages.userLogoutTimeLeft', {
                      timeLeft: remaining,
                    })}
                  </CountdownTypography>
                </>
              )}
            </Grid>
          </Grid>
          <Spacer />
          <Grid item container xs={12} justifyContent="space-around">
            <Grid item xs={5}>
              <StyledButton variant="contained" color="primary" fullWidth onClick={activate}>
                <Trans i18nKey={`common:snackbarButtons.${isSaml ? 'continuePausedSession' : 'stayLoggedIn'}`} />
              </StyledButton>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </>
  );
});
