import memoize from 'fast-memoize';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import { action, computed, observable } from 'mobx';
import { DELTA_PERCENTAGE } from '../../pages/dashboard/balance-sheet/types';
import { DATA_NOT_AVAILABLE_VALUE } from '../api/api-interfaces';
import { CompanySettingsStore } from '../company-settings-store';
import { DataTypes } from '../constants/constants';
import { DashboardSettingsStore } from '../dashboard/dashboard-settings-store';
import { FilterStore } from '../filter/filter-store';
import { MetricCategoryId, MetricFormatTypeId, MetricGroupId, MetricId } from '../graphql/generated/graphql-sdk';
import { IPermissionsStore } from '../permissions/permissions-store';
import { formatPercentageNumber } from '../utilFunctions/chartConfigs';
import { FormatTypes, convertNumDaysToYearAndMonth, formatCurrency, formatNumber } from '../utilFunctions/formatters';
import { MetricService } from './metric-service';
import { MetricDetails, MetricGroupDetails } from './types';

export class MetricStore {
  private permissionsStore: IPermissionsStore;
  private companySettingsStore: CompanySettingsStore;
  private metricService: MetricService;
  private filterStore: FilterStore;
  private dashboardSettingsStore: DashboardSettingsStore;

  public constructor(
    permissionsStore: IPermissionsStore,
    companySettingsStore: CompanySettingsStore,
    metricService: MetricService,
    filterStore: FilterStore,
    dashboardSettingsStore: DashboardSettingsStore
  ) {
    this.permissionsStore = permissionsStore;
    this.companySettingsStore = companySettingsStore;
    this.metricService = metricService;
    this.filterStore = filterStore;
    this.dashboardSettingsStore = dashboardSettingsStore;
  }

  @observable
  private allMetricGroupDetailsHolder: MetricGroupDetails[] | null = null;

  @observable
  public allMetricGroupDetailsLoaded: boolean = false;

  @observable
  private metricToMetricGroupDetailsMapHolder: Record<MetricId, MetricGroupDetails> | null = null;

  @observable
  private metricToMetricDetailsMapHolder: Record<MetricId, MetricDetails> | null = null;

  @observable
  private metricCategoryToSortPositionMapHolder: Record<MetricCategoryId, number> | null = null;

  @observable
  private metricGroupToSortPositionMapHolder: Record<MetricGroupId, number> | null = null;

  @observable
  private metricToSortPositionMapHolder: Record<MetricId, number> | null = null;

  @computed
  public get metricCategoryToSortPositionMap(): Record<MetricCategoryId, Number> {
    if (!this.metricCategoryToSortPositionMapHolder) {
      throw new Error('metricCategoryToSortPositionMap accessed before its loaded');
    } else {
      return this.metricCategoryToSortPositionMapHolder;
    }
  }

  @computed
  public get metricGroupToSortPositionMap(): Record<MetricGroupId, Number> {
    if (!this.metricGroupToSortPositionMapHolder) {
      throw new Error('metricGroupToSortPositionMap accessed before its loaded');
    } else {
      return this.metricGroupToSortPositionMapHolder;
    }
  }

  @computed
  public get metricIdToSortPositionMap(): Record<MetricId, Number> {
    if (!this.metricToSortPositionMapHolder) {
      throw new Error('metricToSortPositionMap accessed before its loaded');
    } else {
      return this.metricToSortPositionMapHolder;
    }
  }

  @computed
  public get allMetricGroupDetails(): MetricGroupDetails[] {
    if (!this.allMetricGroupDetailsHolder) {
      throw new Error("Company metric details accessed before they're loaded");
    } else {
      return this.allMetricGroupDetailsHolder;
    }
  }

  @computed
  public get metricToMetricGroupDetailsMap(): Record<MetricId, MetricGroupDetails> {
    if (!this.metricToMetricGroupDetailsMapHolder) {
      throw new Error("Company metric details accessed before they're loaded");
    } else {
      return this.metricToMetricGroupDetailsMapHolder;
    }
  }

  @computed
  public get metricToMetricDetailsMap(): Record<MetricId, MetricDetails> {
    if (!this.metricToMetricDetailsMapHolder) {
      throw new Error("Company metric details accessed before they're loaded");
    } else {
      return this.metricToMetricDetailsMapHolder;
    }
  }

  @computed
  public get allMetricGroupDetailsWithAllowedMetricIds(): MetricGroupDetails[] {
    const metricDetailsWithAllowedMetricIds =
      this.allMetricGroupDetails.map((m) => {
        return {
          ...m,
          dimensions: m.dimensions.filter((d) => {
            const isDomainAllowed = this.domainAllowedMetricsSet.has(d.id);
            const isPermissionsAllowed = this.permissionsStore.canViewMetric(d.id as unknown as MetricId);
            return isDomainAllowed && isPermissionsAllowed;
          }),
        };
      }) ?? [];
    return metricDetailsWithAllowedMetricIds;
  }

  @computed
  public get domainAllowedMetricsSet(): Set<MetricId> {
    const domainAllowedMetricsSet = new Set(this.companySettingsStore.allowedMetricsForDomain);
    return domainAllowedMetricsSet;
  }

  @computed
  public get domainAllowedMetricGroups(): MetricGroupId[] {
    const allMetricGroups = this.allMetricGroupDetails;
    // Domain Allowed metric groups would be those that have at least one domain allowed metric
    const domainAllowedMetricGroups = allMetricGroups
      .map((g) => {
        return { ...g, dimensions: g.dimensions.filter((d) => this.domainAllowedMetricsSet.has(d.id)) };
      })
      .filter((g) => g.dimensions.length)
      .map((g) => g.id);
    return domainAllowedMetricGroups;
  }

  public getPermissionsAllowedMetricGroups = (): MetricGroupId[] => {
    const allowedMetricGroups = this.allMetricGroupDetailsWithAllowedMetricIds
      .filter((m) => m.dimensions.length)
      .map((m) => m.id);
    return allowedMetricGroups;
  };

  @action
  public loadAllMetricGroupDetails = () => {
    this.allMetricGroupDetailsHolder = this.metricService.getMetricTree();
    this.allMetricGroupDetailsLoaded = true;
    this.loadMetricToMetricGroupDetailsMap();
    this.loadMetricToMetricDetailsMap();
    this.loadAllSortPositions();
  };

  public getMetricGroupDetails = (metricGroupId: MetricGroupId): MetricGroupDetails => {
    const metricGroupDetails = this.allMetricGroupDetails.find((m) => m.id === metricGroupId);
    if (!metricGroupDetails) {
      throw new Error(`Metric not found - ${metricGroupId}`);
    } else {
      return metricGroupDetails;
    }
  };

  public getMetricGroupDetailsFromMetricId = memoize((metric: MetricId): MetricGroupDetails => {
    const metricGroupDetails = this.metricToMetricGroupDetailsMap[metric];
    if (!metricGroupDetails) {
      throw new Error(`Metric group details for metric ${metric} not found`);
    } else {
      return metricGroupDetails;
    }
  });

  public getMetricDetailsFromMetricId = memoize((metric: MetricId): MetricDetails => {
    const metricDetails = this.metricToMetricDetailsMap[metric];
    if (!metricDetails) {
      throw new Error(`Metric group details for metric ${metric} not found`);
    } else {
      return metricDetails;
    }
  });

  public getMetricsForMetricGroup = (metric: MetricGroupId): MetricDetails[] => {
    const metricDetails = this.allMetricGroupDetails.find((m) => m.id === metric);
    if (!metricDetails) {
      throw new Error(`invalid metric id - ${metric}`);
    } else {
      return metricDetails.dimensions;
    }
  };

  public getAllowedSortedMetricIdsForMetricGroup = (metricGroupId: MetricGroupId): MetricDetails[] => {
    const metricDetails = this.allMetricGroupDetailsWithAllowedMetricIds.find((m) => m.id === metricGroupId);
    if (!metricDetails) {
      // TODO: This might happen if some invalid id is in store
      // should have a better handling
      throw new Error(`invalid metric group id - ${metricGroupId}`);
    } else {
      const sortedMetricIds = sortBy(metricDetails.dimensions, (d) => d.defaultPosition);
      return sortedMetricIds;
    }
  };

  public isMetricGroupCohort = (metric: MetricGroupId) => {
    return this.getMetricsForMetricGroup(metric).some((dim) => dim.isCohort);
  };

  public isMetricFinancial = (metric: MetricDetails) => {
    return metric.underlyingFields?.some((f) => f.dataType === DataTypes.FINANCIALMETRICS);
  };

  public isMetricGroupFinancial = (metricGroup: MetricGroupId) => {
    return this.getMetricsForMetricGroup(metricGroup).some((dim) => this.isMetricFinancial(dim));
  };

  @action
  private loadMetricToMetricGroupDetailsMap = () => {
    const metricToMetricGroupDetailsMap: Record<MetricId, MetricGroupDetails> = Object.fromEntries(
      this.allMetricGroupDetails.flatMap((m) => {
        return m.dimensions.map((d) => {
          return [d.id, m];
        });
      })
    ) as Record<MetricId, MetricGroupDetails>;
    this.metricToMetricGroupDetailsMapHolder = metricToMetricGroupDetailsMap;
  };

  @action
  private loadMetricToMetricDetailsMap = () => {
    const metricToMetricDetailsMap: Record<MetricId, MetricDetails> = Object.fromEntries(
      this.allMetricGroupDetails.flatMap((m) => {
        return m.dimensions.map((d) => {
          return [d.id, d];
        });
      })
    ) as Record<MetricId, MetricDetails>;
    this.metricToMetricDetailsMapHolder = metricToMetricDetailsMap;
  };

  @action
  private loadAllSortPositions = () => {
    const categoryGroups = groupBy(this.allMetricGroupDetails, (d) => d.defaultCategory);
    this.metricCategoryToSortPositionMapHolder = Object.fromEntries(
      Object.entries(categoryGroups).map(([category, groups]) => {
        return [category, groups[0].defaultCategoryPosition];
      })
    ) as Record<MetricCategoryId, number>;
    this.metricGroupToSortPositionMapHolder = Object.fromEntries(
      this.allMetricGroupDetails.map((group) => {
        return [group.id, group.defaultPosition];
      })
    ) as Record<MetricGroupId, number>;
    this.metricToSortPositionMapHolder = Object.fromEntries(
      this.allMetricGroupDetails.flatMap((group) => {
        return group.dimensions.map((d) => {
          return [d.id, d.defaultPosition];
        });
      })
    ) as Record<MetricId, number>;
  };

  public getCategoryForMetricGroup = (metricGroup: MetricGroupId) => {
    const domainSettingMetric = this.companySettingsStore.metricGroupCategorySettings.find(
      (m) => m.metricGroup === metricGroup
    );
    return domainSettingMetric?.category ?? this.getMetricGroupDetails(metricGroup).defaultCategory;
  };

  public getCategoryForMetric = (metric: MetricId) => {
    const metricGroupDetails = this.getMetricGroupDetailsFromMetricId(metric);
    const domainSettingMetric = this.companySettingsStore.metricGroupCategorySettings.find(
      (m) => m.metricGroup === metricGroupDetails.id
    );
    return domainSettingMetric?.category ?? metricGroupDetails.defaultCategory;
  };

  private metricFormatTypeToFormatterMap: Record<MetricFormatTypeId, (val: any) => string> = {
    [MetricFormatTypeId.Pmft001Salary]: (val) => {
      const refCurrency = this.companySettingsStore.referenceCurrency() ?? '';
      const displayCurrency = this.dashboardSettingsStore.isLocalCurrency
        ? this.filterStore.currencyToggleOptions.get()[0]
        : refCurrency;
      return formatCurrency(val, displayCurrency);
    },
    [MetricFormatTypeId.Pmft002Percentage]: (val) => {
      return formatPercentageNumber(val);
    },
    [MetricFormatTypeId.Pmft004DaysToYearMonth]: (val) => {
      return convertNumDaysToYearAndMonth(Number(val));
    },
    [MetricFormatTypeId.Pmft005Count]: (val) => {
      return formatNumber(val, FormatTypes.COUNT);
    },
    [MetricFormatTypeId.Pmft006Number]: (val) => {
      return formatNumber(val, FormatTypes.NUMBER);
    },
    [MetricFormatTypeId.Pmft007SensitiveNumber]: (val) => {
      return formatNumber(val, FormatTypes.SENSITIVE_NUMBER);
    },
    [MetricFormatTypeId.Pmft008Multiple]: (val) => {
      return `${formatNumber(val, FormatTypes.NUMBER)}x`;
    },
    [MetricFormatTypeId.Pmft009Leaves]: (val) => {
      return formatNumber(val, FormatTypes.LEAVES_TAKEN);
    },
    [MetricFormatTypeId.Pmft010Hours]: (val) => {
      return formatNumber(val, FormatTypes.HOURS);
    },
    [MetricFormatTypeId.Pmft011Fte]: (val) => {
      return formatNumber(val, FormatTypes.FTE);
    },
    [MetricFormatTypeId.Pmft012FloatingNumber1]: (val) => {
      return formatNumber(val, FormatTypes.FLOATING_NUMBER_1);
    },
    [MetricFormatTypeId.Pmft013FloatingNumber2]: (val) => {
      return formatNumber(val, FormatTypes.FLOATING_NUMBER_2);
    },
    [MetricFormatTypeId.Pmft014FloatingPercentage1]: (val) => {
      return formatPercentageNumber(val, FormatTypes.FLOATING_PERCENTAGE_1);
    },
    [MetricFormatTypeId.Pmft015FloatingPercentage2]: (val) => {
      return formatPercentageNumber(val, FormatTypes.FLOATING_PERCENTAGE_2);
    },
  };

  public formatMetricValue = (val: number | string | null, metricId: MetricId, header?: string) => {
    const metricFormatType = this.getMetricDetailsFromMetricId(metricId).formatType;
    if (val === null) {
      return DATA_NOT_AVAILABLE_VALUE;
    }
    if (header === DELTA_PERCENTAGE) {
      return formatPercentageNumber(Number(val));
    }
    return this.metricFormatTypeToFormatterMap[metricFormatType](val);
  };
}
