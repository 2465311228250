import { Button, InputBase, MenuItem, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { routingStore } from '../../../contexts';
import { ROUTE_URLS } from '../../constants/constants';
import { Role } from '../../permissions/permissions';
import { useGetUserRolePreferences } from '../../queryHooks/user-role-preferences-hooks';
import { rootStore } from '../../store/root-store';
import { darkTeal } from '../../theme/default-theme';
import { formatUserBasedOnDisplayNameAndLocale } from '../../utilFunctions/pure-utils';
import LoadingOverlay from '../utils/LoadingOverlay';
import PopUpMenu from '../utils/PopUpMenu';

enum AccountMenus {
  ACCOUNT = 'account',
  SWITCH_ROLE = 'switch-role',
  CHANGE_ROLE = 'change-role',
}

const SelectedMenu = styled(MenuItem)<{
  isSelected: boolean;
}>`
  background-color: ${(props) => (props.isSelected ? `${darkTeal}!important` : 'inherit')};
`;

const SearchInput = styled(InputBase)`
  width: 100%;
  height: 25px !important;
  font-size: 0.8em;

  & .input {
    padding: 8px;
    transition: all 0.2s;
    height: 25px;
    width: 200px;
    color: white;
  }
`;

export const Account = observer(() => {
  const { t } = useTranslation();

  const executorRole = rootStore.permissionsStore.getExecutorRole();
  const { selectedLanguage } = rootStore.languageStore;
  const { handleSetExecutorRole } = rootStore.permissionsStore;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openMenu, setOpenMenu] = useState<AccountMenus | ''>('');
  const [searchRoleInput, setSearchRoleInput] = useState<string>('');
  const { data: userRolePreferences, isPending } = useGetUserRolePreferences();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, menuName: AccountMenus) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(menuName);
  };

  const handleChangeRoleMenuOpen = (_e: React.MouseEvent<HTMLElement>, menuName: AccountMenus) => {
    setOpenMenu(menuName);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu('');
  };

  const logout = () => {
    //This was taken out of authStore.logOutUser to remove a cyclical dependency
    rootStore.permissionsStore.stopRoleSimulation();
    rootStore.authStore.logOutUser();
  };

  const clearCache = async () => {
    (await caches.keys()).forEach((cache) => caches.delete(cache));
    localStorage.clear();
    window.location.reload();
  };

  const switchRoleText = (currentlySimulatingRole: Role | null) => {
    if (currentlySimulatingRole != null) {
      return `${t('common:panalytRoleSwitcher.role')}: ${currentlySimulatingRole.name}`;
    } else {
      return `${t('common:panalytRoleSwitcher.switchRole')}`;
    }
  };

  const handleMyAccountClick = () => {
    const { push } = routingStore;
    handleMenuClose();
    push(ROUTE_URLS.MY_ACCOUNT);
  };

  const rolesThatCanBeSimulated = () => {
    return (rootStore.domainDependencyStore.getUserRoles() || []).filter((role: Role) => {
      const currentlySimulatingRole = rootStore.permissionsStore.currentlySimulatingRole();
      if (currentlySimulatingRole === null) {
        // Let's not show the user's own role (unless they are simulating a role that, itself, can simulate the user's own role)
        return role.id !== rootStore.permissionsStore.getUserRoleId();
      } else {
        // Let's not show the role that the user is currently already simulating
        return role.id !== currentlySimulatingRole.id;
      }
    });
  };

  const handleResetRole = () => {
    rootStore.permissionsStore.handleStopRoleSimulation();
  };

  const handleRoleSelection = (role: Role) => {
    rootStore.permissionsStore.handleSimulateRole(role);
  };

  const setSearchRoleInputDebounced = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchRoleInput(e.target.value);
  });

  const allExecutorRoles = rootStore.permissionsStore
    .getAllExecutorRoles()
    .slice()
    // sorting selected at the top, then alphabetical
    .sort((ra, rb) => {
      if (ra.id === executorRole.id) {
        return -1;
      } else if (rb.id === executorRole.id) {
        return 1;
      } else {
        return ra.name.localeCompare(rb.name);
      }
    });

  const searchedExecutorRoles = allExecutorRoles.filter(({ name }) => {
    return name.toLowerCase().includes(searchRoleInput.toLowerCase());
  });

  const userRoles = rolesThatCanBeSimulated().sort((r1, r2) => r1.name.localeCompare(r2.name));

  const accountMenuItems = (
    <>
      {rootStore.permissionsStore.canManageOwnUserAccount() && (
        <MenuItem onClick={handleMyAccountClick} className="intercom_appbar_account">
          {t('common:shell.account.myAccount')}
        </MenuItem>
      )}
      {
        <MenuItem
          onClick={(e) => handleChangeRoleMenuOpen(e, AccountMenus.CHANGE_ROLE)}
          className="intercom_appbar_change_roles"
        >
          {t('common:shell.account.changeRoles')}
        </MenuItem>
      }
      {rootStore.enabledFeaturesService.isSwitchRoleEnabled() &&
        rootStore.permissionsStore.userPermissionsCanSimulateRoles() && (
          <MenuItem
            onClick={(e) => handleChangeRoleMenuOpen(e, AccountMenus.SWITCH_ROLE)}
            className="intercom_appbar_simulate_roles"
          >
            <Typography variant="body2" color="textSecondary">
              {switchRoleText(rootStore.permissionsStore.currentlySimulatingRole())}
            </Typography>
          </MenuItem>
        )}
      <MenuItem onClick={clearCache} className="intercom_appbar_clearcache">
        {t('common:shell.account.clearCache')}
      </MenuItem>
      <MenuItem onClick={logout} className="intercom_appbar_logout">
        {t('common:shell.account.logout')}
      </MenuItem>
      <Typography
        variant="body2"
        color="textSecondary"
        mx={2}
        my={1}
        sx={{ 'font-style': 'italic', 'font-size': 'x-small' }}
      >
        {t('common:shell.app.version', { version: process.env.REACT_APP_GIT_SHA })}
      </Typography>
    </>
  );

  const switchRoleMenuItems = (
    <>
      {rootStore.permissionsStore.currentlySimulatingRole() !== null && (
        <MenuItem onClick={() => handleResetRole()} className="intercom_appbar_simulate_roles_reset_role">
          {t('common:panalytRoleSwitcher.reset')}
        </MenuItem>
      )}
      {rootStore.permissionsStore.currentlySimulatingRole() !== null && <hr />}
      {userRoles.map((role: Role) => (
        <MenuItem
          key={role.id}
          onClick={() => handleRoleSelection(role)}
          className="intercom_appbar_simulate_roles_select_role"
        >
          {role.name}
        </MenuItem>
      ))}
    </>
  );

  const changeRoleMenuItems = (
    <>
      <SearchInput
        placeholder={t('common:shell.searchRoles')}
        classes={{ input: 'input' }}
        onChange={setSearchRoleInputDebounced}
        className={'intercom_change_roles_search'}
      />
      {searchedExecutorRoles.map((role) => (
        <SelectedMenu
          key={role.id}
          onClick={() => handleSetExecutorRole(role)}
          className="intercom_appbar_permissions_change_roles"
          isSelected={executorRole.id === role.id}
        >
          {rootStore.permissionsStore.getNonUserRoleName(role)}
        </SelectedMenu>
      ))}
    </>
  );

  const profile = userRolePreferences?.settings?.profile;
  if (isPending) {
    return <LoadingOverlay />;
  }
  const name = userRolePreferences && profile && formatUserBasedOnDisplayNameAndLocale(profile, selectedLanguage.id);
  const displayName = name || rootStore.authStore.currentUser?.displayName || '';

  return (
    <>
      <Button onClick={(e) => handleMenuOpen(e, AccountMenus.ACCOUNT)} className="intercom_appbar_switch_role">
        <Typography variant="body2" color="textSecondary">
          {t('common:shell.hello')}
          {' ' + displayName + ''}
        </Typography>
      </Button>
      <PopUpMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} placement="bottom-end">
        {openMenu === AccountMenus.ACCOUNT && accountMenuItems}
        {openMenu === AccountMenus.CHANGE_ROLE && changeRoleMenuItems}
        {openMenu === AccountMenus.SWITCH_ROLE && switchRoleMenuItems}
      </PopUpMenu>
    </>
  );
});
