import moment, { Moment } from 'moment';
import { dimensionToDataFieldWithDataType } from '../../../../../common-types';
import i18n from '../../../../../i18n';
import {
  ApiGetManagerResponse,
  ApiHierarchyItem,
  ApiMasterDataAdvancedQuery,
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataQuery,
} from '../../../api/api-interfaces';
import {
  employeeDataApiService,
  TimeSliderConfigStartAndEnd,
} from '../../../api/employee-data-service/employee-data-service';
import { cachedMasterDataApiService } from '../../../api/master-data-service';
import { PerformanceCycle } from '../../../api/zod-schemas';
import {
  DataFields,
  DataTypes,
  Domains,
  EmployeeDataFields,
  EvaluationDataFields,
  JobDataFields,
  OfferDataFields,
  Operations,
} from '../../../constants/constants';
import { Granularity, Months } from '../../../date-manager/date-manager-constants';
import { dateManagerService } from '../../../date-manager/date-manager-service';
import { getStartOfFiscalYear } from '../../../date-manager/date-manager-utils';
import { nullFilter, TimeSliderConfig } from '../../../filter/filter-store';
import { arrangeIntoTree, getVersionFilterFromDate } from '../../../filter/utils';
import { GetDimensionValuesForTimeConfigService } from '../../../services/GetDimensionValuesForTimeConfigService';
import { GetDimensionValuesService } from '../../../services/GetDimensionValuesService';
import {
  GetResponseForAdvancedMasterQueryService,
  GetResponseForAdvancedQueryServiceOptions,
} from '../../../services/GetResponseForAdvancedQueryService';
import { GetResponseForMasterQueryService } from '../../../services/GetResponseForQueryService';
import { rootStore } from '../../../store/root-store';
import { sortApiHierarchyItemByValues, sortBySorterArray, sortFilterTrayValues } from '../../../utilFunctions/sorters';
import { getValue } from '../../../utilFunctions/utils';
import { FilterTrayItemProps } from '../FilterTrayItem';

export const getFilterValuesForDimensionAtVersionId = async (
  dimension: ApiMasterDataAdvancedQueryDimension,
  timeConfig?: TimeSliderConfig,
  options?: GetResponseForAdvancedQueryServiceOptions
) => {
  const result = await GetDimensionValuesForTimeConfigService({
    dimension,
    timeConfig,
    options,
  });
  const filterValues: ApiHierarchyItem[] = result.map((r) =>
    r === null
      ? nullFilter
      : {
          name: String(r),
        }
  );
  const sortedFilterValues = sortFilterTrayValues(filterValues, dimensionToDataFieldWithDataType(dimension));
  return sortedFilterValues;
};

export const getManagers = async (managerId: string, versionId: string) => {
  if (!rootStore.filterStore.employeeData) {
    // since its used in getting employee name from id for managers
    await rootStore.filterStore.loadEmployeeData();
  }
  const reportingLine: ApiGetManagerResponse = await employeeDataApiService.getReportingLine(
    rootStore.companyStore.domain as Domains,
    versionId,
    managerId
  );
  let mappedData: FilterTrayItemProps[] = arrangeIntoTree(reportingLine);
  return mappedData;
};

export const getEvaluationFilters = async () => {
  await rootStore.companyStore.getSelectedCompany();
  const evaluationCyclesFromBackendQuery: ApiMasterDataQuery = {
    dataType: DataTypes.EVALUATION,
    dimensions: [
      EvaluationDataFields.EVALUATION_CYCLE_TYPE,
      EvaluationDataFields.EVALUATION_AS_OF,
      EvaluationDataFields.EVALUATION_CYCLE_NAME,
    ],
  };
  const evaluationCyclesFromBackend = await GetResponseForMasterQueryService(evaluationCyclesFromBackendQuery);
  const evaluationCyclesInfo = rootStore.companySettingsStore.evaluationCyclesInfo();
  const evaluationFiltersForType: Record<string, ApiHierarchyItem[]> = {};
  evaluationCyclesFromBackend?.dataPoints?.forEach((dp) => {
    const cycleType = getValue(dp.dimensions, {
      dataType: DataTypes.EVALUATION,
      dataField: EvaluationDataFields.EVALUATION_CYCLE_TYPE,
    });
    const cycleDate = getValue(dp.dimensions, {
      dataType: DataTypes.EVALUATION,
      dataField: EvaluationDataFields.EVALUATION_AS_OF,
    });
    const cycleName = getValue(dp.dimensions, {
      dataType: DataTypes.EVALUATION,
      dataField: EvaluationDataFields.EVALUATION_CYCLE_NAME,
    });
    const cycleInfo = evaluationCyclesInfo.find(
      (cycle: PerformanceCycle) => cycle.cycleType === cycleType && cycle.cycleDate === cycleDate
    );
    if (!cycleInfo) {
      return;
    } else {
      const { evaluationValuesToScoreMap, scoreSortingOrder } = cycleInfo;

      const sortedEvaluationValues = sortBySorterArray(
        Object.keys(evaluationValuesToScoreMap),
        scoreSortingOrder ?? []
      );
      const scoreFilters: ApiHierarchyItem[] = sortedEvaluationValues.map((key) => {
        const score = evaluationValuesToScoreMap[key];
        return {
          name: key,
          filterValues: [
            {
              dataType: 'EVALUATION',
              operation: 'EQUAL',
              property: 'EVALUATION_CYCLE_TYPE',
              values: [cycleType],
            },
            {
              dataType: 'EVALUATION',
              operation: 'EQUAL',
              property: 'EVALUATION_AS_OF',
              values: [cycleDate],
            },
            {
              dataType: 'EVALUATION',
              operation: 'EQUAL',
              property: 'EVALUATION_SCORE',
              values: [score],
            },
          ],
        };
      });

      if (!evaluationFiltersForType[cycleName]) {
        evaluationFiltersForType[cycleName] = [];
      }
      evaluationFiltersForType[cycleName].push(...scoreFilters);
    }
  });
  const evaluationFilters: ApiHierarchyItem[] = Object.entries(evaluationFiltersForType).map(
    ([cycleName, cycleFilters]) => ({
      name: cycleName,
      subItems: cycleFilters,
      isSelectable: false,
    })
  );
  const sortedEvaluationFilters = sortFilterTrayValues(evaluationFilters, {
    dataType: DataTypes.EVALUATION,
    dataField: EvaluationDataFields.EVALUATION_CYCLE_TYPE,
  });
  return sortedEvaluationFilters;
};

export const getCustomFieldFilters = async (
  fieldNumber: number,
  dataType: DataTypes,
  timeConfig: TimeSliderConfigStartAndEnd
): Promise<ApiHierarchyItem[]> => {
  const result = await cachedMasterDataApiService.listCustomField(
    rootStore.companyStore.domain as Domains,
    fieldNumber,
    dataType,
    timeConfig
  );
  const field = { dataType, dataField: `CUSTOM_FIELD_${fieldNumber}` as DataFields };
  const formattedResult = sortApiHierarchyItemByValues(result?.items ?? [], field) || [];
  const customFieldFilters = formattedResult.map((element: ApiHierarchyItem) => {
    return {
      name: element.name,
      subItems: element.subItems,
    };
  });
  return customFieldFilters;
};

export const getHireYearFilters = async () => {
  const { endDate, firstMonthOfYear } = rootStore.timeSliderStore.timeSliderConfig;
  const hireYearsQuery: ApiMasterDataAdvancedQuery = {
    dataType: DataTypes.EMPLOYEE,
    dimensions: [
      {
        dataType: DataTypes.EMPLOYEE,
        property: EmployeeDataFields.START_DATE,
      },
    ],
    filterItems: [
      getVersionFilterFromDate(dateManagerService.parseApiDate(endDate), firstMonthOfYear, DataTypes.EMPLOYEE),
    ],
  };

  const hireYearsResponse = await GetResponseForAdvancedMasterQueryService(hireYearsQuery);

  const hireYears = Array.from(
    new Set(
      hireYearsResponse.dataPoints
        .map((d) => new Date(d.dimensions[0].value).getFullYear())
        .filter((date) => !isNaN(date))
        .map((date) => date.toString())
    )
  );

  const getStartOfFiscalYearWhichIncludesGivenYear = (givenYear: string): Moment => {
    return getStartOfFiscalYear(moment(), firstMonthOfYear).year(Number(givenYear));
  };

  const { parseDate, formatDateApi } = dateManagerService;
  return [
    {
      name: 'Calendar Year',
      displayName: i18n.t('common:filterTray.hireYearValues.calendarYear'),
      isSelectable: false,
      subItems: hireYears.map((year) => ({
        name: year,
        displayName: year,
        filterValues: [
          {
            dataType: DataTypes.EMPLOYEE,
            operation: Operations.GREATER_THAN_OR_EQUAL_TO,
            property: EmployeeDataFields.START_DATE,
            values: [formatDateApi(moment().year(Number(year)).startOf(Granularity.YEAR))],
          },
          {
            dataType: DataTypes.EMPLOYEE,
            operation: Operations.LESS_THAN_OR_EQUAL_TO,
            property: EmployeeDataFields.START_DATE,
            values: [formatDateApi(moment().year(Number(year)).endOf(Granularity.YEAR))],
          },
        ],
      })),
    },
    ...(firstMonthOfYear === Months.January
      ? []
      : [
          {
            name: 'Financial Year',
            displayName: i18n.t('common:filterTray.hireYearValues.financialYear'),
            isSelectable: false,
            subItems: hireYears.map((year) => ({
              name: year,
              displayName: `${i18n.t('common:filterTray.hireYearValues.financialYearAbbreviation')}${year.slice(2)}`,
              filterValues: [
                {
                  dataType: DataTypes.EMPLOYEE,
                  operation: Operations.GREATER_THAN_OR_EQUAL_TO,
                  property: EmployeeDataFields.START_DATE,
                  // Need to test if this whole change in Hire Year filters is working correctly
                  values: [dateManagerService.formatDateApi(getStartOfFiscalYearWhichIncludesGivenYear(year))],
                },
                {
                  dataType: DataTypes.EMPLOYEE,
                  operation: Operations.LESS_THAN_OR_EQUAL_TO,
                  property: EmployeeDataFields.START_DATE,
                  values: [
                    dateManagerService.formatDateApi(
                      getStartOfFiscalYearWhichIncludesGivenYear(year)
                        .add(1, Granularity.YEAR)
                        .subtract(1, Granularity.DAY)
                    ),
                  ],
                },
              ],
            })),
          },
        ]),
  ];
};

// Recruitment Stuff

const jobStatusValuesToDisplayKeyMap: Record<string, string> = {
  open: 'common:filterTray.jobStatusValues.open',
  closed: 'common:filterTray.jobStatusValues.closed',
  draft: 'common:filterTray.jobStatusValues.draft',
  archived: 'common:filterTray.jobStatusValues.archived',
};

export const getJobStatusValues = async (): Promise<ApiHierarchyItem[]> => {
  const jobStatusResult = await GetDimensionValuesService({
    dimension: { dataType: DataTypes.JOB, dataField: JobDataFields.STATUS },
    dataType: DataTypes.JOB,
  });
  const jobStatusValues = jobStatusResult.map((r) => ({
    name: r,
    displayKey: jobStatusValuesToDisplayKeyMap[r.toLocaleLowerCase()] || undefined,
  }));
  return jobStatusValues;
};

const hireStatusValuesToDisplayKeyMap: Record<string, string> = {
  accepted: 'common:filterTray.hireStatusValues.accepted',
  rejected: 'common:filterTray.hireStatusValues.rejected',
  rejcted: 'common:filterTray.hireStatusValues.rejected',
  unresolved: 'common:filterTray.hireStatusValues.unresolved',
  deprecated: 'common:filterTray.hireStatusValues.deprecated',
  others: 'common:commonValues.misc.others',
};

export const getHireStatusValues = async (): Promise<ApiHierarchyItem[]> => {
  const hireStatusResult = await GetDimensionValuesService({
    dimension: { dataType: DataTypes.OFFER, dataField: OfferDataFields.STATUS },
    dataType: DataTypes.OFFER,
  });
  const hireStatusValues = hireStatusResult.map((r) => ({
    name: r,
    displayKey: hireStatusValuesToDisplayKeyMap[r.toLocaleLowerCase()] || undefined,
  }));
  return hireStatusValues;
};
