import memoize from 'fast-memoize';
import { action, computed, observable, runInAction, toJS, when } from 'mobx';
import { DataFieldWithDataType } from '../../../common-types';
import i18n from '../../../i18n';
import {
  AllowedDashboard,
  ApiDashboard,
  ApiMasterDataTypes,
  EXECUTOR_ROLE_KEY,
  SIMULATED_ROLE_KEY,
} from '../api/api-interfaces';
import { PermissionService } from '../api/permission-service';
import { DISABLED_DATA_VIEW_FOR_DASHBOARDS, DISABLED_DATA_VIEW_FOR_DOMAINS } from '../company-data-view-settings';
import { CompanyStore } from '../company/company-store';
import { DashboardPage } from '../components/dashboard/dashboard-master';
import { FilterTraySectionConfig } from '../components/filter-tray/filter-tray-store';
import { Dashboards, DataFields, DataTypes, Domains } from '../constants/constants';
import { EmployeeDataStore } from '../employee-data/employee-data-store';
import { MetricId, ReadData } from '../graphql/generated/graphql-sdk';
import { localStore } from '../local-store';
import { recruitmentRoutes } from '../routes/route-utils';
import { trackAPIError, trackAPIErrorSimple, trackError, trackErrorSimple } from '../sentry/sentry';
import { ErrorTypes } from '../sentry/types';
import { InitialDependencyStore } from '../startup/initial-dependency-store';
import { rootStore } from '../store/root-store';
import { enumValues } from '../utilFunctions/pure-utils';
import { getFilterFieldFromTag, isHierarchical, splitViewMetricTag } from '../utilFunctions/utils';
import { AssignedPermissions, Permission, PermissionTypes, Role, RoleId, ViewMetricsTagType } from './permissions';

interface RoleWithPermissions {
  role: Role;
  directPermissions: AssignedPermissions<Permission>;
}

export interface IPermissionsStore {
  readonly canManageAliases: boolean;

  //Datafields
  isFieldAllowedForExecutorRole(field: DataFieldWithDataType): boolean;

  // Metrics
  canViewMetric(metricId: MetricId): boolean;

  // Filters
  enabledFilters(
    companyFilters: FilterTraySectionConfig[],
    allowedDataTypes: ApiMasterDataTypes[]
  ): FilterTraySectionConfig[];

  // Dashboards
  getAllowedDashboardsForUser(
    allDashboards: ApiDashboard[],
    companyAllowedDashboards: AllowedDashboard[]
  ): ApiDashboard[];
  canSeeDashboardPage(dbId: Dashboards, pageId: string): Boolean;
  canSeeDataView(dashboard?: Dashboards, currentPage?: DashboardPage): boolean;
  canExportDataView(dbId: Dashboards): Boolean;
  canExportChartView(dbId: Dashboards): Boolean;

  // Navigation
  allowedDomains(): Set<Domains> | null; // TODO use a better type/concept to describe "all allowed (= null) | limited to Domains[]"
  canSeeAdminMenu(): boolean;
  canSeeSuperAdminMenu(): boolean;

  // Administration
  readonly canManageAllDomains: boolean; // This includes all permissions to manage all domains and e.g. their allowed dashboards
  readonly userPermissionsCanManageAllDomains: boolean; // like canManageAllDomains but always uses user permissions and never simulated permissions
  canChangeUserStatus(): boolean;
  canSeePrivateUserConfig(): boolean; // For now this includes only reporting-line-flag, but later also last-login and roles etc.
  canManageOwnUserAccount(): boolean;

  // Reports
  canExportReports(): boolean;

  // Impersonation
  couldNotFetchPermissions: 'insufficientPermissions' | 'networkError' | null;
  permissionsError: boolean;
  handleSimulateRole(role: Role): void;
  currentlySimulatingRole(): Role | null;
  getSimulateRoleId(): string | null;
  effectiveRoleId(): RoleId; // This returns the simulated role if a role is simulated, otherwise the user's role
  stopRoleSimulation(): void;
  handleStopRoleSimulation(): void;
  getUserRoleId(): RoleId;
  userPermissionsCanSimulateRoles(): boolean; // Always uses user permissions and never simulated permissions
  permissionsReady(): boolean;

  // Role Selection
  executorRoleError: boolean;
  initializeExecutorRole(): Promise<void>;
  getExecutorRole(): Role;
  getAllExecutorRoles(): Role[];
  handleSetExecutorRole(role: Role): void;
  executorRoleReady(): boolean;

  // Upload data
  canUploadData(): boolean;

  // Role Attributes
  getNonUserRoleName(name: Role): string;
  isReservedRoleName(name: string): boolean;
  isRoleEditable(roleWithPermissions: RoleWithPermissions): boolean;
  isRoleDeletable(roleWithPermissions: RoleWithPermissions): boolean;
  isRoleCopyable(roleWithPermissions: RoleWithPermissions): boolean;

  // roleScope
  canViewRoleScopeForRole(roleId: string): boolean;
  isRoleEditableScopeForRole(roleId: string): boolean;
  canAccessMyFilterGroups(): boolean;

  // permission pages
  canReadUserPermissions(): boolean;
  canWriteUserPermissions(): boolean;
  canReadRoleSettings(): boolean;
  canWriteRoleSettings(): boolean;
}

export enum RoleTypes {
  USER_ROLE = 'USER_ROLE',
  NON_USER_ROLE = 'NON_USER_ROLE',
}
export interface RoleWithRoleType extends Role {
  roleType: RoleTypes;
}

export enum ReservedRoleNames {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  GLOBAL_ACCESS = 'Global Access',
}
export class PermissionBasedPermissionsStore implements IPermissionsStore {
  private companyStore: CompanyStore;
  private permissionService: PermissionService;
  private initialDependencyStore: InitialDependencyStore;
  private employeeDataStore: EmployeeDataStore;

  /**
   * We use an observable for the permissions so that they can be loaded on application startup once the user has logged in
   * After that, since the amount of different (recursively allowed) permissions can be fairly high and they are used in many places,
   * we use a computed value to avoid converting from the observable to a plain javascript object for every permission-check
   */

  @observable
  private permissions: AssignedPermissions<Permission> | null = null;
  @observable
  private userRoleId: RoleId | null = null;
  @observable
  private executorRole: Role | null = null;
  @observable
  public executorRoleError: boolean = false;
  @observable
  private executorRolePermissions: AssignedPermissions<Permission> | null = null;
  @observable
  private allExecutorRoles: Role[] | null = null;
  @observable
  private nonUserRoles: Role[] | null = null;
  @observable
  private userRoles: Role[] | null = null;
  @observable
  public couldNotFetchPermissions: 'insufficientPermissions' | 'networkError' | null = null;

  // We use null here if role-simulation is currently not happening. Otherwise the values will not be null
  private simulatedPermissions: AssignedPermissions<Permission> | null = null;
  private simulatedRole: Role | null = null;

  @computed
  private get effectivePermissions(): AssignedPermissions<Permission> {
    return this.simulatedPermissions || this.getPermissions;
  }

  @computed
  public get getPermissions(): AssignedPermissions<Permission> {
    if (!this.permissions) {
      throw new Error('Permissions are not yet initialized');
    } else {
      return new AssignedPermissions(this.permissions);
    }
  }

  @computed
  private get getExecutorRolePermissions(): AssignedPermissions<Permission> {
    if (this.executorRolePermissions === null) {
      const errorMsg = 'permissionsStore.executorRolePermissions called before it is available';
      trackError(errorMsg);
      throw new Error(errorMsg);
    } else {
      return new AssignedPermissions(this.executorRolePermissions);
    }
  }

  private findUserRoleId = (userPermissions: AssignedPermissions<Permission>) => {
    // This is a dirty way of finding the roleId - we should get it properly by asking the backend what our roleId is.
    const roleId = userPermissions.first()?.roleId;
    if (roleId === undefined) {
      const errorMsg =
        "findUserRoleId couldn't deduce the user role id from the user's permissions. Falling back to invalid role";
      console.error(errorMsg, userPermissions);
      // Dirt workaround: we keep the application working by setting an invalid role, but not failing the whole application.
      // Once we only use only new permission-system everywhere, we should just completely fail and don't use this fallback.
      return '<ERROR_NO_ROLE_SET>';
    } else {
      return roleId;
    }
  };

  public permissionsReady = (): boolean => {
    // Need to check all observables here to make sure they are loaded before the page (Shell.tsx) renders the UI
    return (
      this.permissions !== null && this.userRoleId !== null && this.nonUserRoles !== null && this.userRoles !== null
    );
  };

  public executorRoleReady = (): boolean => {
    return (
      this.executorRole !== null && this.executorRolePermissions !== null && (this.allExecutorRoles?.length ?? 0) > 0
    );
  };

  private isFieldAllowedInPermission = memoize((field: DataFieldWithDataType, permission?: ReadData) => {
    const hasFullAccess = permission?.access === null ?? false;
    const hasSpecificFieldAccess =
      permission?.access?.some(
        (a) =>
          a.datatype === field.dataType &&
          (a.fields?.specificDatafields?.some((f) => f.datafield === field.dataField) ?? false)
      ) ?? false;
    return hasFullAccess || hasSpecificFieldAccess;
  });

  public isFieldAllowedForExecutorRole = (field: DataFieldWithDataType): boolean => {
    const executorRoleReadDataPermissions = this.getExecutorRolePermissions
      .filterByType(PermissionTypes.ReadData)
      .first();
    return this.isFieldAllowedInPermission(field, executorRoleReadDataPermissions?.permission);
  };

  @computed
  private get canViewAllMetrics(): boolean {
    const metricPermission = this.getExecutorRolePermissions
      .validFor(this.domain())
      .filterByType(PermissionTypes.ViewMetrics)
      .first()?.permission;

    if (metricPermission) {
      return metricPermission.allowedTags === null;
    } else {
      return false;
    }
  }

  @computed
  private get allowedSingleMetricTags(): MetricId[] {
    const metricPermission = this.getExecutorRolePermissions
      .validFor(this.domain())
      .filterByType(PermissionTypes.ViewMetrics)
      .first()?.permission;

    return (metricPermission?.allowedTags ?? [])
      .map((tag) => {
        const parsedViewMetricTag = splitViewMetricTag(tag);
        if (parsedViewMetricTag && parsedViewMetricTag.tagType === ViewMetricsTagType.SINGLE) {
          if (Object.values(MetricId).includes(parsedViewMetricTag.tagValue as MetricId)) {
            return parsedViewMetricTag.tagValue as MetricId;
          } else {
            const error = `probably new backend metric that we don't know about yet or some kind of metric id mismatch. Tag is - ${tag}`;
            console.error(error);
            trackErrorSimple(new Error(error), ErrorTypes.METRIC_MISMATCH);
            return null;
          }
        } else {
          const error = `some kind of metric tag type that we don't support yet. Should log sentry error. Tag is - ${tag}`;
          console.error(error);
          trackErrorSimple(new Error(error), ErrorTypes.METRIC_MISMATCH);
          return null;
        }
      })
      .flatMap((elem) => (elem ? [elem] : []));
  }

  public canViewMetric = memoize((metricId: MetricId): boolean => {
    return this.canViewAllMetrics || this.allowedSingleMetricTags.includes(metricId);
  });

  @observable
  public permissionsError = false;

  public constructor(
    companyStore: CompanyStore,
    permissionService: PermissionService,
    initialDependencyStore: InitialDependencyStore,
    employeeDataStore: EmployeeDataStore
  ) {
    this.companyStore = companyStore;
    this.permissionService = permissionService;
    this.initialDependencyStore = initialDependencyStore;
    this.employeeDataStore = employeeDataStore;

    when(
      () => this.initialDependencyStore.initialDependenciesReady(),
      () => {
        Promise.resolve(this.initialDependencyStore.getAllPermissionsForCurrentUser())
          .then((p) => {
            const permissions = new AssignedPermissions(p);
            this.permissions = permissions;

            const storedSimulatedRoleStringified = localStore.get(SIMULATED_ROLE_KEY);

            if (storedSimulatedRoleStringified) {
              const processRoleSimulationUpdates = async () => {
                try {
                  const storedSimulatedRole = JSON.parse(storedSimulatedRoleStringified) as Role;
                  this.simulatedRole = storedSimulatedRole;
                  // simulatedPermissions must be set before userRoleId, because userRoleId being null prevents the UI to load
                  // Otherwise the UI would load with simulatedPermissions = null, which would mean there is no simulation happening, even though it's just a timing issue
                  const simulatedPermissions = await this.permissionService.getAllPermissionsForRole(
                    storedSimulatedRole.id
                  );
                  this.simulatedPermissions = new AssignedPermissions(simulatedPermissions);
                } catch (error) {
                  console.error(`Error when preparing role-simulation. Falling back to not simulating a role`, error);
                  runInAction(() => (this.permissionsError = true));
                  this.simulatedPermissions = null;
                  this.simulatedRole = null;
                } finally {
                  this.userRoleId = this.findUserRoleId(permissions);
                }
              };
              processRoleSimulationUpdates();
            } else {
              this.userRoleId = this.initialDependencyStore.getUserRoleId();
            }
            return permissions;
          })
          .then(async () => {
            // Right now, permission-simulation only works for panalyt-superadmin, since only those can see all roles.
            // To avoid sentry-errors, we only call allRoles() only when the user can simulate roles.
            if (this.userPermissionsCanSimulateRoles()) {
              const [nonUserRoles, userRoles] = await Promise.all([
                this.permissionService.listNonUserRoles(this.companyStore.domain),
                this.permissionService.listUserRoles(this.companyStore.domain),
              ]);
              this.nonUserRoles = nonUserRoles;
              this.userRoles = userRoles;
            } else {
              this.nonUserRoles = [];
              this.userRoles = [];
            }
          })
          .catch((error) => {
            console.error(`Error when setting up permissions`, error);
            const errorMessage: string = error.message ?? '';
            const networkRequestFailedRegex = /Network request failed/i;

            if (networkRequestFailedRegex.test(errorMessage)) {
              this.couldNotFetchPermissions = 'networkError';
            } else {
              // Optimally we should check the errorCode of the backend to see for what reason it failed
              this.couldNotFetchPermissions = 'insufficientPermissions';
            }

            trackAPIError(
              new Error(`Error when setting up permissions`),
              {
                error: error,
              },
              {},
              ErrorTypes.PERMISSIONS_LOADING_FAILED
            );
            runInAction(() => (this.permissionsError = true));
          });
      }
    );

    // To initialize executor role
    when(
      () => Boolean(this.companyStore.domain) && this.permissionsReady(),
      async () => {
        await this.initializeExecutorRole();
        this.checkMinimumPermissions();
      }
    );
  }

  @action
  private checkMinimumPermissions = () => {
    const validMinimumPermissionSets = [
      [PermissionTypes.Login, PermissionTypes.ReadData, PermissionTypes.ViewDashboards],
      [PermissionTypes.Login, PermissionTypes.ReadClientFile, PermissionTypes.WriteClientFile],
    ];
    const hasMinPermissions = validMinimumPermissionSets.some((permissionSet) => {
      return permissionSet.every((permission) => {
        return this.effectivePermissions.filterByType(permission).nonEmpty();
      });
    });
    const missingExecutorRole = this.executorRole === null;
    if (!hasMinPermissions) {
      this.permissionsError = true;
      trackAPIErrorSimple(new Error(`Insufficient Permissions`), ErrorTypes.MINIMUM_PERMISSIONS_NOT_PRESENT);
    }
    if (missingExecutorRole) {
      this.permissionsError = true;
      trackAPIErrorSimple(new Error(`Executor Roles setup error`), ErrorTypes.EXECUTOR_ROLE_SETUP_ERROR);
    }
  };

  @action
  public initializeExecutorRole = async () => {
    try {
      const currentlySimulatingRole = this.currentlySimulatingRole();
      if (this.userRoleId) {
        var allExecutorRoles: Role[] = [];
        try {
          if (currentlySimulatingRole) {
            allExecutorRoles =
              (
                await this.permissionService.listExecutorRolesForRole(
                  {
                    roleId: this.effectiveRoleId(),
                    domainFilter: this.domain(),
                    simulateRole: currentlySimulatingRole.id,
                  },
                  this.domain()
                )
              ).listExecutorRolesForRole ?? [];
          } else {
            allExecutorRoles = (this.initialDependencyStore.getExecutorRoles() ?? []).filter(
              (r) => r.domain === this.domain()
            );
          }
        } catch (e) {
          if (currentlySimulatingRole) {
            console.warn(
              `Executor Roles list is empty, but we are simulating role ${currentlySimulatingRole.id} with name ${currentlySimulatingRole.name} so maybe it's a non-user role and this is expected. Falling back to using this role itself as executor role`
            );
            allExecutorRoles.push(currentlySimulatingRole); // Setting executor-roles to the currently simulating role
          } else {
            console.error("Couldn't get executor roles", e);
            allExecutorRoles = [];
            throw new Error("Couldn't get executor roles");
          }
        }
        if (!allExecutorRoles.length) {
          this.executorRoleError = true;
          throw new Error(`Executor Roles list is unexpectedly empty for user-role ${this.userRoleId}`);
        }

        this.allExecutorRoles = allExecutorRoles;
        const storedExecutorRoleStringified = localStore.get(EXECUTOR_ROLE_KEY);
        const storedExecutorRole = JSON.parse(storedExecutorRoleStringified ?? '{}') as Role;
        const isValidExecutorRole = allExecutorRoles.find((r) => r.id === storedExecutorRole.id);
        if (isValidExecutorRole) {
          this.setExecutorRole(storedExecutorRole);
          this.executorRolePermissions = new AssignedPermissions(
            await this.permissionService.getDirectPermissionsForRole(storedExecutorRole.id)
          );
        } else {
          // Note that this here will become unreliable once clients can rename roles themselves (including superadmin role)
          const superAdminRole = allExecutorRoles.find((r) => r.name === ReservedRoleNames.SUPERADMIN);
          const defaultExecutorRole = (superAdminRole ?? allExecutorRoles.first() ?? currentlySimulatingRole) as Role;
          this.setExecutorRole(defaultExecutorRole);
          this.executorRolePermissions = new AssignedPermissions(
            await this.permissionService.getDirectPermissionsForRole(defaultExecutorRole.id)
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  private domain = (): Domains => this.companyStore.domain as Domains;

  @computed
  private get canManageThisDomain() {
    return this.effectivePermissions.filterByType(PermissionTypes.ManageDomain).validFor(this.domain()).nonEmpty();
  }

  @computed
  private get canManageCompany() {
    return this.effectivePermissions.filterByType(PermissionTypes.ManageCompany).validFor(this.domain()).nonEmpty();
  }

  @computed
  public get canManageAliases(): boolean {
    return this.effectivePermissions.filterByType(PermissionTypes.ManageCompany).validFor(this.domain()).nonEmpty();
  }

  public enabledFilters = memoize(
    (companyFilters: FilterTraySectionConfig[], allowedDataTypes: ApiMasterDataTypes[]): FilterTraySectionConfig[] => {
      const allowedFilters = companyFilters.filter((f) => this.isFilterAllowed(f));
      const filters = allowedFilters.filter((f) => {
        const { dimension, dependsOn, subItems, noDefaultSubItems } = f;
        if (!this.checkConfidentiality(dimension)) {
          return false;
        }
        if (!this.isFieldInData(dependsOn || dimension, allowedDataTypes)) {
          return false;
        }
        if (subItems && subItems.length === 0 && !noDefaultSubItems) {
          return false;
        }
        return true;
      });
      return filters;
    }
  );

  @computed
  private get filterPermissionForExecutorRole() {
    return this.getExecutorRolePermissions.validFor(this.domain()).filterByType(PermissionTypes.UseFilters).first()
      ?.permission;
  }

  @computed
  private get metricPermissionForExecutorRole() {
    return this.getExecutorRolePermissions.validFor(this.domain()).filterByType(PermissionTypes.ViewMetrics).first()
      ?.permission;
  }

  private isFilterAllowed = memoize((filter: FilterTraySectionConfig): boolean => {
    const { dataType, dataField } = filter.dimension;
    const useFilterPermission = this.filterPermissionForExecutorRole;
    if (!useFilterPermission) {
      return false;
    } else {
      const allowedTags = useFilterPermission.allowedTags;
      if (allowedTags === null) {
        return true;
      }
      const allowedFilters = allowedTags.map((t) => getFilterFieldFromTag(t));
      const filterAllowed = allowedFilters.deepCompareContains({ dataType, dataField });
      return filterAllowed;
    }
  });

  private checkConfidentiality(dimension: DataFieldWithDataType): boolean {
    if (isHierarchical(dimension)) {
      return rootStore.permissionsStore.isFieldAllowedForExecutorRole({
        dataType: dimension.dataType,
        dataField: `${dimension.dataField}_LEVEL_1` as DataFields,
      });
    } else {
      return rootStore.permissionsStore.isFieldAllowedForExecutorRole(dimension);
    }
  }

  private isFieldInData(field: DataFieldWithDataType, allowedDataTypes: (ApiMasterDataTypes | DataTypes)[]): boolean {
    if (allowedDataTypes.includes(field.dataType)) {
      switch (field.dataType) {
        case DataTypes.EMPLOYEE:
        case DataTypes.JOINERS_VIEW:
          const { nonNullFields } = this.employeeDataStore;
          const modifiedNonNullFields = nonNullFields.map((f) => {
            const indexOfLevel = f.dataField.indexOf('_LEVEL_');
            return {
              dataType: f.dataType,
              dataField: indexOfLevel !== -1 ? f.dataField.slice(0, indexOfLevel) : f.dataField,
            };
          });
          return modifiedNonNullFields.deepCompareContains(field);
        case DataTypes.APPLICATION:
        case DataTypes.JOB:
        case DataTypes.OFFER:
          return recruitmentRoutes.some((r) => window.location.href.includes(r));
        case DataTypes.QUESTIONANSWER:
          return window.location.href.includes('survey');
        case DataTypes.EVALUATION:
          return true;
      }
    }
    return false;
  }

  @computed
  private get dashboardPermissionsForExecutorRole() {
    return this.getExecutorRolePermissions.filterByType(PermissionTypes.ViewDashboards).validFor(this.domain()).first()
      ?.permission;
  }

  public getAllowedDashboardsForUser = (
    allDashboards: ApiDashboard[],
    companyAllowedDashboards: AllowedDashboard[]
  ): ApiDashboard[] => {
    const allowedDashboardsForCompany = allDashboards.filter((db) =>
      companyAllowedDashboards.some((d) => d.id === db.id)
    );

    const isPermittedDashboard = (dashboard: ApiDashboard) => {
      const dbPermission = this.dashboardPermissionsForExecutorRole;
      if (dbPermission && dbPermission.dashboardPages === null) {
        return true;
      } else {
        const allowedDashboards = dbPermission?.dashboardPages?.map((dbp) => dbp.dashboard) ?? [];
        return allowedDashboards.includes(dashboard.id);
      }
    };

    const allowedDashboards = allowedDashboardsForCompany.filter((db) => isPermittedDashboard(db));

    return allowedDashboards;
  };

  public canSeeDashboardPage = (dbId: Dashboards, pageId: string): Boolean => {
    return this.getExecutorRolePermissions
      .filterByType(PermissionTypes.ViewDashboards)
      .validFor(this.domain())
      .some((ap) => {
        const dashboardPages = ap.permission.dashboardPages;
        if (dashboardPages === null) {
          return true;
        } else {
          return dashboardPages.find(
            (dbp) => dbp.dashboard === dbId && (dbp.pages === null || dbp.pages.includes(pageId))
          );
        }
      });
  };

  public canSeeDataView = (dashboard?: Dashboards, currentPage?: DashboardPage): boolean => {
    const allowedByPreviousLogic = !(
      (DISABLED_DATA_VIEW_FOR_DOMAINS.includes(this.companyStore.domain as Domains) && !this.canManageCompany) ||
      (dashboard && DISABLED_DATA_VIEW_FOR_DASHBOARDS.includes(dashboard)) ||
      (currentPage?.pageOptions?.disableDataView ?? false)
    );

    let allowedByPermissionLogic = false;
    if (dashboard) {
      allowedByPermissionLogic = this.getExecutorRolePermissions
        .filterByType(PermissionTypes.ViewDashboards)
        .validFor(this.domain())
        .some((ap) => ap.permission.viewDataView === null || ap.permission.viewDataView.includes(dashboard));
    }

    return allowedByPreviousLogic && allowedByPermissionLogic;
  };

  public canExportDataView = (dbId: Dashboards): Boolean => {
    return this.getExecutorRolePermissions
      .filterByType(PermissionTypes.ViewDashboards)
      .validFor(this.domain())
      .some((ap) => ap.permission.exportDataView === null || ap.permission.exportDataView.includes(dbId));
  };
  public canExportChartView = (dbId: Dashboards): Boolean => {
    return this.getExecutorRolePermissions
      .filterByType(PermissionTypes.ViewDashboards)
      .validFor(this.domain())
      .some((ap) => ap.permission.exportChartView === null || ap.permission.exportChartView.includes(dbId));
  };

  // Navigation

  public allowedDomains = (): Set<Domains> | null => {
    const loginPermissions = this.effectivePermissions.filterByType(PermissionTypes.Login);
    if (loginPermissions.some((ap) => ap.permission.domain === null)) {
      return null; // access to all domains
    } else {
      // @ts-ignore
      return new Set(loginPermissions.map((ap) => ap.permission.domain));
    }
  };

  public canSeeAdminMenu = (): boolean => {
    const domainPermissions = this.effectivePermissions.validFor(this.domain());
    return (
      domainPermissions.filterByType(PermissionTypes.ManageCompany).nonEmpty() ||
      domainPermissions.filterByType(PermissionTypes.ManageUsers).nonEmpty() ||
      domainPermissions.filterByType(PermissionTypes.ReadClientFile).nonEmpty() ||
      domainPermissions.filterByType(PermissionTypes.WriteClientFile).nonEmpty()
    );
  };

  public canSeeSuperAdminMenu = (): boolean => {
    return this.canManageThisDomain;
  };

  // Administration

  @computed
  public get canManageAllDomains(): boolean {
    return !!this.effectivePermissions
      .filterByType(PermissionTypes.ManageDomain)
      .map((ap) => ap.permission.domain === null).length;
  }

  @computed
  public get userPermissionsCanManageAllDomains(): boolean {
    return !!this.getPermissions.filterByType(PermissionTypes.ManageDomain).map((ap) => ap.permission.domain === null)
      .length;
  }

  public canChangeUserStatus = (): boolean => {
    return this.effectivePermissions.validFor(this.domain()).filterByType(PermissionTypes.ManageUsers).nonEmpty();
  };

  // Currently reporting-line flag can't be changed/set for superadmins and admins, later we might allow this
  public canSeePrivateUserConfig = (): boolean => {
    return this.effectivePermissions.validFor(this.domain()).filterByType(PermissionTypes.ManageUsers).nonEmpty();
  };

  public canManageOwnUserAccount = (): boolean => {
    const domainPermissions = this.effectivePermissions.validFor(this.domain());
    return (
      domainPermissions.filterByType(PermissionTypes.ManageCompany).nonEmpty() &&
      domainPermissions.filterByType(PermissionTypes.ManageUsers).nonEmpty()
    );
  };

  // Reports
  @computed
  private get exportReportsPermissionForExecutorRole() {
    return this.getExecutorRolePermissions.validFor(this.domain()).filterByType(PermissionTypes.ExportReports).first()
      ?.permission;
  }

  public canExportReports = (): boolean => {
    return this.exportReportsPermissionForExecutorRole !== undefined;
  };

  // Upload data

  public canUploadData(): boolean {
    return this.effectivePermissions.validFor(this.domain()).filterByType(PermissionTypes.WriteClientFile).nonEmpty();
  }

  private simulateRole = async (role: Role) => {
    return this.permissionService.getAllPermissionsForRole(role.id).then((permissions) => {
      this.simulatedPermissions = new AssignedPermissions(permissions);
      this.simulatedRole = role;
      localStore.set(SIMULATED_ROLE_KEY, JSON.stringify(role));
    });
  };

  public handleSimulateRole = (role: Role) => {
    localStore.remove(EXECUTOR_ROLE_KEY);
    this.simulateRole(role).then(() => location.reload());
  };

  public currentlySimulatingRole = () => {
    return this.simulatedRole;
  };

  public getSimulateRoleId = () => {
    return this.currentlySimulatingRole()?.id ?? null;
  };

  public effectiveRoleId = () => {
    return this.simulatedRole?.id ?? this.getUserRoleId();
  };

  public getExecutorRole = () => {
    if (this.executorRole === null) {
      const errorMsg = 'permissionsStore.executorRole called before it is available';
      throw new Error(errorMsg);
    } else {
      return this.executorRole;
    }
  };

  public getAllExecutorRoles = () => {
    if (this.allExecutorRoles === null) {
      const errorMsg = 'permissionsStore.allExecutorRoles called before it is available';
      throw new Error(errorMsg);
    } else {
      return this.allExecutorRoles;
    }
  };

  @action
  private setExecutorRole = (role: Role) => {
    this.executorRole = role;
    localStore.set(EXECUTOR_ROLE_KEY, JSON.stringify(role));
  };

  public handleSetExecutorRole = (role: Role) => {
    this.setExecutorRole(role);
    location.reload();
  };

  public stopRoleSimulation = () => {
    this.simulatedPermissions = null;
    this.simulatedRole = null;
    localStore.remove(SIMULATED_ROLE_KEY);
    /**
     * When switching from role X to a different role (such as superadmin),
     * it's important to remove the executor role for X from local storage.
     * Otherwise, the app will retain the executor role for X after reloading,
     * which may not be the desired behavior. By removing the executor role for X,
     * the app will reset to the default view for the new role (e.g. the superadmin view).
     * */
    localStore.remove(EXECUTOR_ROLE_KEY);
  };

  public handleStopRoleSimulation = () => {
    this.stopRoleSimulation();
    location.reload();
  };

  public getUserRoleId = () => {
    if (!this.userRoleId) {
      throw new Error('User role not yet initialized');
    } else {
      return toJS(this.userRoleId);
    }
  };

  public userPermissionsCanSimulateRoles = () => {
    return this.userPermissionsCanManageAllDomains;
  };

  private reservedRoleNameToTranslatedNameMap: Record<ReservedRoleNames, string> = {
    [ReservedRoleNames.GLOBAL_ACCESS]: i18n.t(
      `common:permissions.reservedRoleNames.${ReservedRoleNames.GLOBAL_ACCESS}`
    ),
    [ReservedRoleNames.SUPERADMIN]: i18n.t(`common:permissions.reservedRoleNames.${ReservedRoleNames.SUPERADMIN}`),
    [ReservedRoleNames.ADMIN]: i18n.t(`common:permissions.reservedRoleNames.${ReservedRoleNames.ADMIN}`),
  };

  public isReservedRoleName = (name: string) => {
    const reservedRoleNames = enumValues(ReservedRoleNames);
    if (reservedRoleNames.some((n) => n.toLowerCase() === name.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  private getReservedRoleTranslatedName = (name: ReservedRoleNames) => {
    return this.reservedRoleNameToTranslatedNameMap[name];
  };

  public getNonUserRoleName = (nonUserRole: Role) => {
    return nonUserRole && this.isReservedRoleName(nonUserRole.name)
      ? this.getReservedRoleTranslatedName(nonUserRole.name as ReservedRoleNames)
      : nonUserRole?.name ?? '???';
  };

  public isRoleEditable = (roleWithPermissions: RoleWithPermissions): boolean => {
    const uneditableRoleNames = [
      ReservedRoleNames.SUPERADMIN,
      ReservedRoleNames.ADMIN,
      ReservedRoleNames.GLOBAL_ACCESS,
    ];
    const { role, directPermissions } = roleWithPermissions;
    const isSpecialUneditableRole = Boolean(
      uneditableRoleNames.find((r) => r.toLowerCase() === role.name.toLowerCase())
    );
    const isAdminRole = Boolean(directPermissions.findByType(PermissionTypes.GrantPermissions));
    const canCurrentRoleEditSelectedRole = !this.effectivePermissions
      ?.findByType(PermissionTypes.GrantPermissions)
      ?.permission.excludedRoles.includes(role.id);
    return !isAdminRole && !isSpecialUneditableRole && canCurrentRoleEditSelectedRole;
  };

  public isRoleDeletable = (roleWithPermissions: RoleWithPermissions): boolean => {
    return this.isRoleEditable(roleWithPermissions);
  };

  public isRoleCopyable = (roleWithPermissions: RoleWithPermissions): boolean => {
    const uncopyableRoleName = [ReservedRoleNames.SUPERADMIN, ReservedRoleNames.ADMIN];
    const { role } = roleWithPermissions;
    const isSpecialUncopyableRole = Boolean(
      uncopyableRoleName.find((r) => r.toLowerCase() === role.name.toLowerCase())
    );
    return !isSpecialUncopyableRole;
  };

  public canViewRoleScopeForRole = (roleId: string): boolean => {
    return this.effectivePermissions
      .validFor(this.companyStore.domain)
      .filterByType(PermissionTypes.ViewRoleScope)
      .some((r) => {
        const { role, excludedRoles } = r.permission;
        const isTargetRoleExcluded = excludedRoles.includes(roleId);
        const canViewAllRoles = role === null;
        const canViewTargetRole = role === roleId;
        return !isTargetRoleExcluded && (canViewAllRoles || canViewTargetRole);
      });
  };

  public isRoleEditableScopeForRole = (roleId: string): boolean => {
    return this.effectivePermissions
      .validFor(this.companyStore.domain)
      .filterByType(PermissionTypes.EditRoleScope)
      .some((r) => {
        const { role, excludedRoles } = r.permission;
        const isTargetRoleExcluded = excludedRoles.includes(roleId);
        const canEditAllRoles = role === null;
        const canEditTargetRole = role === roleId;
        return !isTargetRoleExcluded && (canEditAllRoles || canEditTargetRole);
      });
  };

  public canAccessMyFilterGroups = (): boolean => {
    const effectiveRoleId = this.effectiveRoleId();
    return (
      this.canManageAllDomains ||
      (this.canViewRoleScopeForRole(effectiveRoleId) && this.isRoleEditableScopeForRole(effectiveRoleId))
    );
  };

  private isSuperAdmin = (): boolean => {
    return this.effectivePermissions.filterByType(PermissionTypes.AssumeRole).some((p) => p.permission.roles === null);
  };

  public canReadUserPermissions = (): boolean => {
    // all superadmins
    return this.canManageAllDomains || this.isSuperAdmin();
  };

  public canWriteUserPermissions = (): boolean => {
    // all superadmins
    return this.canManageAllDomains || this.isSuperAdmin();
  };

  public canReadRoleSettings = (): boolean => {
    // all superadmins
    return this.canManageAllDomains || this.isSuperAdmin();
  };

  public canWriteRoleSettings = (): boolean => {
    // all superadmins
    return this.canManageAllDomains || this.isSuperAdmin();
  };
}
