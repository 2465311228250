import { useQuery } from '@tanstack/react-query';
import { DataFieldWithDataType, dimensionToDataFieldWithDataType } from '../../../../../common-types';
import { queryClient } from '../../../../app';
import {
  ApiHierarchyItem,
  ApiHierarchyItemList,
  ApiMasterDataAdvancedQueryDimension,
} from '../../../api/api-interfaces';
import {
  employeeDataApiService,
  TimeSliderConfigStartAndEnd,
} from '../../../api/employee-data-service/employee-data-service';
import { cachedMasterDataApiService } from '../../../api/master-data-service';
import {
  ApplicationDataFields,
  DataFields,
  DataTypes,
  Domains,
  EmployeeDataFields as EDF,
  EvaluationDataFields,
  JobDataFields,
  OfferDataFields,
} from '../../../constants/constants';
import { TimeSliderConfig } from '../../../filter/filter-store';
import { getDatesInRange } from '../../../filter/utils';
import { GetResponseForAdvancedQueryServiceOptions } from '../../../services/GetResponseForAdvancedQueryService';
import { rootStore } from '../../../store/root-store';
import { sortApiHierarchyItemByValues } from '../../../utilFunctions/sorters';
import {
  getCustomFieldFilters,
  getEvaluationFilters,
  getFilterValuesForDimensionAtVersionId,
  getHireStatusValues,
  getHireYearFilters,
  getJobStatusValues,
  getManagers,
} from './customGetterFunctions';

type DataService = (domain: Domains, timeConfig?: TimeSliderConfigStartAndEnd) => Promise<ApiHierarchyItemList>;

export interface QueryFnForDimensionOptions {
  timeSliderConfig: TimeSliderConfig;
}

type ProcessedDataService = (options: QueryFnForDimensionOptions) => Promise<ApiHierarchyItem[]>;

const getProcessedEmployeeDataService = (
  dataService: DataService,
  dimension: ApiMasterDataAdvancedQueryDimension
): ProcessedDataService => {
  return async (options: Pick<QueryFnForDimensionOptions, 'timeSliderConfig'>) => {
    const { domain } = rootStore.companyStore;
    const result = (await dataService(domain as Domains, options.timeSliderConfig))?.items ?? [];
    const formattedResult = sortApiHierarchyItemByValues(result, dimensionToDataFieldWithDataType(dimension));
    return formattedResult;
  };
};

const getProcessedNonEmpDataService = (
  dataService: DataService,
  dimension: ApiMasterDataAdvancedQueryDimension
): ProcessedDataService => {
  return async () => {
    const { domain } = rootStore.companyStore;
    // TODO: Not passing in versionId here as sometimes this is later than the latest versionId
    // for the non emp dataType. This is because we don't limit time slider to correct range
    // on non emp dbs. Need to fix later
    const result = (await dataService(domain as Domains))?.items ?? [];
    const formattedResult = sortApiHierarchyItemByValues(result, dimensionToDataFieldWithDataType(dimension));
    return formattedResult;
  };
};

// Note: These(getFilterValues) functions are only used for non-hierarchical fields
const getFilterValuesForDimensionQueryFn =
  (dimension: ApiMasterDataAdvancedQueryDimension, options?: GetResponseForAdvancedQueryServiceOptions) =>
  (opt: QueryFnForDimensionOptions) => {
    return getFilterValuesForDimensionAtVersionId(dimension, opt.timeSliderConfig, options);
  };

const getFilterValuesForEmployeeDimensionQueryFn = (property: EDF) =>
  getFilterValuesForDimensionQueryFn({ dataType: DataTypes.EMPLOYEE, property }, { loadInBackground: true });

// Not passing in version id here for same reason as mentioned above
const getFilterValuesForNonEmpDimensionQueryFn = (dimension: ApiMasterDataAdvancedQueryDimension) => () =>
  getFilterValuesForDimensionAtVersionId(dimension);

export enum OtherFilters {
  // These are filters on Emp Table but which don't necessarily map to a specific emp field
  PULSE_SCORES = 'PULSE_SCORES',
  SURVEY_SCORES = 'SURVEY_SCORES',
}

type DimensionToDataGetterMap = Partial<
  Record<DataTypes, Partial<Record<DataFields | OtherFilters, ProcessedDataService>>>
>;

const dimensionToDataGetterMap: DimensionToDataGetterMap = {
  [DataTypes.EMPLOYEE]: {
    [EDF.LOCATION as DataFields]: getProcessedEmployeeDataService(employeeDataApiService.listLocations, {
      // need any because location name conflicts in DataFields(since it has both JobDataFields.Location and EDF.Location)
      dataType: DataTypes.EMPLOYEE,
      property: EDF.LOCATION,
    }),
    [EDF.ORGANIZATION as DataFields]: getProcessedEmployeeDataService(employeeDataApiService.listOrganizations, {
      dataType: DataTypes.EMPLOYEE,
      property: EDF.ORGANIZATION,
    }),
    [EDF.JOB_GRADE]: getProcessedEmployeeDataService(employeeDataApiService.listJobGrades, {
      dataType: DataTypes.EMPLOYEE,
      property: EDF.JOB_GRADE,
    }),
    [EDF.EMPLOYMENT_TYPE]: getProcessedEmployeeDataService(employeeDataApiService.listEmploymentTypes, {
      dataType: DataTypes.EMPLOYEE,
      property: EDF.EMPLOYMENT_TYPE,
    }),
    [EDF.FUNCTION]: getProcessedEmployeeDataService(employeeDataApiService.listFunctions, {
      dataType: DataTypes.EMPLOYEE,
      property: EDF.FUNCTION,
    }),
    [EDF.POSITION]: getProcessedEmployeeDataService(employeeDataApiService.listPositions, {
      dataType: DataTypes.EMPLOYEE,
      property: EDF.POSITION,
    }),
    [EDF.NATIONALITY_HIERARCHICAL]: getProcessedEmployeeDataService(employeeDataApiService.listNationalities, {
      dataType: DataTypes.EMPLOYEE,
      property: EDF.NATIONALITY_HIERARCHICAL,
    }),
    [EDF.RECRUITMENT_CATEGORY_HIERARCHICAL]: getProcessedEmployeeDataService(
      employeeDataApiService.listRecruitmentCategories,
      {
        dataType: DataTypes.EMPLOYEE,
        property: EDF.RECRUITMENT_CATEGORY_HIERARCHICAL,
      }
    ),
    [EDF.GENDER]: getFilterValuesForEmployeeDimensionQueryFn(EDF.GENDER),
    [EDF.LOCAL_OR_NON_LOCAL]: getFilterValuesForEmployeeDimensionQueryFn(EDF.LOCAL_OR_NON_LOCAL),
    [EDF.AGE_GROUP as DataFields]: () => Promise.resolve(rootStore.synchronousDataStore.ageGroups),
    [EDF.AGE as DataFields]: getFilterValuesForEmployeeDimensionQueryFn(EDF.AGE),
    [EDF.TENURE_GROUP as DataFields]: () => Promise.resolve(rootStore.synchronousDataStore.tenureGroups),
    [EDF.MARITAL_STATUS]: getFilterValuesForEmployeeDimensionQueryFn(EDF.MARITAL_STATUS),
    [EDF.JOB_TITLE]: getFilterValuesForEmployeeDimensionQueryFn(EDF.JOB_TITLE),
    [EDF.RECRUITMENT_CATEGORY as DataFields]: getFilterValuesForEmployeeDimensionQueryFn(EDF.RECRUITMENT_CATEGORY),
    [EDF.SOCIAL_TYPE]: getFilterValuesForEmployeeDimensionQueryFn(EDF.SOCIAL_TYPE),
    [EDF.PERFORMANCE_REGULAR_CYCLE]: getFilterValuesForEmployeeDimensionQueryFn(EDF.PERFORMANCE_REGULAR_CYCLE),
    [EDF.MANAGER_ID]: (options) => getManagers('root', options.timeSliderConfig.endDate),
    [EDF.MANAGER_OR_IC]: getFilterValuesForEmployeeDimensionQueryFn(EDF.MANAGER_OR_IC),
    [EDF.DEFINED_MANAGER]: getFilterValuesForEmployeeDimensionQueryFn(EDF.DEFINED_MANAGER),
    [EDF.START_DATE]: () => getHireYearFilters(),
    [EDF.REGRET_ATTRITION]: getFilterValuesForEmployeeDimensionQueryFn(EDF.REGRET_ATTRITION),
    // Custom Fields
    ...Array(10)
      .fill(0)
      .reduce((obj, _, i) => {
        const customField = `CUSTOM_FIELD_${i + 1}` as EDF;
        obj[customField] = (options: QueryFnForDimensionOptions) => {
          return getCustomFieldFilters(i + 1, DataTypes.EMPLOYEE, options.timeSliderConfig);
        };
        return obj;
      }, {}),
    // Custom Filters
    ...Array(20)
      .fill(0)
      .reduce((obj, _, i) => {
        const customFilter = `CUSTOM_FILTER_${i + 1}` as EDF;
        obj[customFilter] = getFilterValuesForEmployeeDimensionQueryFn(customFilter);
        return obj;
      }, {}),
  },
  [DataTypes.EVALUATION]: {
    [EvaluationDataFields.EVALUATION_CYCLE_TYPE]: () => getEvaluationFilters(),
  },
  [DataTypes.JOB]: {
    [JobDataFields.LOCATION as DataFields]: getProcessedNonEmpDataService(cachedMasterDataApiService.listJobOffices, {
      dataType: DataTypes.JOB,
      property: JobDataFields.LOCATION,
    }),
    [JobDataFields.ORGANIZATION as DataFields]: getProcessedNonEmpDataService(
      cachedMasterDataApiService.listJobOrganizations,
      {
        dataType: DataTypes.JOB,
        property: JobDataFields.ORGANIZATION,
      }
    ),
    [JobDataFields.JOB_NAME as DataFields]: getProcessedNonEmpDataService(cachedMasterDataApiService.listJobNames, {
      dataType: DataTypes.JOB,
      property: JobDataFields.JOB_NAME,
    }),
    [JobDataFields.STATUS as DataFields]: () => getJobStatusValues(),
    [JobDataFields.JOB_GRADE as DataFields]: getProcessedNonEmpDataService(
      cachedMasterDataApiService.listJobJobGrades,
      {
        dataType: DataTypes.JOB,
        property: JobDataFields.JOB_GRADE,
      }
    ),
    [JobDataFields.FUNCTION as DataFields]: getProcessedNonEmpDataService(cachedMasterDataApiService.listJobFunctions, {
      dataType: DataTypes.JOB,
      property: JobDataFields.FUNCTION,
    }),
    [JobDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL as DataFields]: getProcessedNonEmpDataService(
      cachedMasterDataApiService.listJobRecruitmentCategories,
      {
        dataType: DataTypes.JOB,
        property: JobDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL,
      }
    ),
    // Custom Fields
    ...Array(2)
      .fill(0)
      .reduce((obj, _, i) => {
        const customField = `CUSTOM_FIELD_${i + 1}` as JobDataFields;
        obj[customField] = (options: QueryFnForDimensionOptions) => {
          return getCustomFieldFilters(i + 1, DataTypes.JOB, options.timeSliderConfig);
        };
        return obj;
      }, {}),
  },
  [DataTypes.APPLICATION]: {
    [ApplicationDataFields.STATUS as DataFields]: () =>
      Promise.resolve(rootStore.synchronousDataStore.applicationStatusValues),
    [ApplicationDataFields.STANDARDIZED_CURRENT_STAGE]: () =>
      Promise.resolve(rootStore.synchronousDataStore.currentStageValues),
    [ApplicationDataFields.START_YEAR]: getFilterValuesForNonEmpDimensionQueryFn({
      dataType: DataTypes.APPLICATION,
      property: ApplicationDataFields.START_YEAR,
    }),
    [ApplicationDataFields.START_MONTH]: getFilterValuesForNonEmpDimensionQueryFn({
      dataType: DataTypes.APPLICATION,
      property: ApplicationDataFields.START_MONTH,
    }),
    [ApplicationDataFields.CURRENT_JOB_TITLE]: getFilterValuesForNonEmpDimensionQueryFn({
      dataType: DataTypes.APPLICATION,
      property: ApplicationDataFields.CURRENT_JOB_TITLE,
    }),
    [ApplicationDataFields.REJECTION_REASON]: getFilterValuesForNonEmpDimensionQueryFn({
      dataType: DataTypes.APPLICATION,
      property: ApplicationDataFields.REJECTION_REASON,
    }),
    [ApplicationDataFields.SOURCE]: getProcessedNonEmpDataService(cachedMasterDataApiService.listApplicationSources, {
      dataType: DataTypes.APPLICATION,
      property: ApplicationDataFields.SOURCE,
    }),
    [ApplicationDataFields.DEGREE_TYPE]: getFilterValuesForNonEmpDimensionQueryFn({
      dataType: DataTypes.APPLICATION,
      property: ApplicationDataFields.DEGREE_TYPE,
    }),
    [ApplicationDataFields.DEGREE_MAJOR]: getFilterValuesForNonEmpDimensionQueryFn({
      dataType: DataTypes.APPLICATION,
      property: ApplicationDataFields.DEGREE_MAJOR,
    }),
    ...Array(1)
      .fill(0)
      .reduce((obj, _, i) => {
        const customField = `CUSTOM_FIELD_${i + 1}` as EDF;
        obj[customField] = (options: QueryFnForDimensionOptions) => {
          return getCustomFieldFilters(i + 1, DataTypes.APPLICATION, options.timeSliderConfig);
        };
        return obj;
      }, {}),
    ...Array(5)
      .fill(0)
      .reduce((obj, _, i) => {
        const customFilter = `CUSTOM_FILTER_${i + 1}` as EDF;
        obj[customFilter] = getFilterValuesForDimensionQueryFn(
          { dataType: DataTypes.APPLICATION, property: customFilter },
          { loadInBackground: true }
        );
        return obj;
      }, {}),
  },
  [DataTypes.OFFER]: {
    [OfferDataFields.STATUS as DataFields]: () => getHireStatusValues(),
  },
  [DataTypes.TIMEANDATTENDANCEMONTHLY]: {
    ...Array(5)
      .fill(0)
      .reduce((obj, _, i) => {
        const customField = `CUSTOM_FIELD_${i + 1}` as EDF;
        obj[customField] = getFilterValuesForDimensionQueryFn(
          { dataType: DataTypes.TIMEANDATTENDANCEMONTHLY, property: customField },
          { loadInBackground: true }
        );
        return obj;
      }, {}),
  },
};

// Using this function for dimensions which aren't usually used in filter tray but
// need to be present in this list for some one off use case. Adding it to the main list
// might be confusing so I want to explicitly store them separately.
const specialDimensionToDataGetterMap: DimensionToDataGetterMap = {
  [DataTypes.EMPLOYEE]: {
    // JOB_GRADE_LEVEL_2 needed for hoosiers default filter
    [EDF.JOB_GRADE_LEVEL_2 as DataFields]: getFilterValuesForEmployeeDimensionQueryFn(EDF.JOB_GRADE_LEVEL_2),
  },
};

export const getQueryFnForDimension = (dimension: DataFieldWithDataType) => {
  const queryFn: ProcessedDataService =
    // @ts-ignore (ts error occurse here because there are some duplicate fields in our DataFields union)
    dimensionToDataGetterMap[dimension.dataType]?.[dimension.dataField] ??
    // @ts-ignore
    specialDimensionToDataGetterMap[dimension.dataType]?.[dimension.dataField];
  if (!queryFn) {
    const errorMsg = `QueryFn for ${dimension.dataType} ${dimension.dataField} not found`;
    console.error(errorMsg);
    throw new Error(errorMsg);
  }
  return queryFn;
};

const useDataHookQueryName = 'dataHierarchy';
export const getUseDataHookQueryKey = (dimension: DataFieldWithDataType, dates: string[]) => {
  return [useDataHookQueryName, dimension, dates.join(',')];
};

const useDataHook = (dimension: DataFieldWithDataType, timeSliderConfig: TimeSliderConfig) => {
  // TODO: Add stronger type checking, such that if given dimension doesn't have a queryFunction specified,
  // it throws a type error
  const queryFn = getQueryFnForDimension(dimension);
  const dates = getDatesInRange(timeSliderConfig);
  return useQuery({
    queryKey: getUseDataHookQueryKey(dimension, dates),
    queryFn: () => queryFn({ timeSliderConfig }),
    placeholderData: (prev) => prev, // TODO: investigate if this is really what we want: https://github.com/TanStack/query/discussions/6460
  });
};

export const prefetchData = (dimension: DataFieldWithDataType, timeSliderConfig: TimeSliderConfig) => {
  const queryFn = getQueryFnForDimension(dimension);
  const dates = getDatesInRange(timeSliderConfig);
  return queryClient.prefetchQuery({
    queryKey: getUseDataHookQueryKey(dimension, dates),
    queryFn: () => queryFn({ timeSliderConfig }),
  });
};

export const useOptionalDataHook = (
  dimension: DataFieldWithDataType,
  timeSliderConfig: TimeSliderConfig,
  enabled: boolean
) => {
  // TODO: usually hooks shouldn't be placed in conditions
  // For this to work, the enabled value should never change between rerenders for a given component.
  // That way usual problem of different number of hooks between different renders shouldn't arise.
  // Our current usage meets this condition so this should work fine.
  // Still maybe I should avoid this. Will look for better patterns
  if (enabled) {
    return useDataHook(dimension, timeSliderConfig);
  } else {
    return { data: [] as ApiHierarchyItem[], isLoading: false };
  }
};
