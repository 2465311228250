import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Info from '@mui/icons-material/Info';
import {
  ClickAwayListener,
  Collapse,
  Grid,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClickEvent } from '../../../../common-types';
import { Dashboards } from '../../constants/constants';
import { dashboardConfigurations } from '../../dashboard/dashboard-configurations';
import { teal2 } from '../../theme/default-theme';
import { styled } from '../styled';

export interface NavBarItemObj {
  id: string;
  name: string;
  link: string;
  subItems?: any[];
  disabled?: boolean;
  className?: string;
}
interface NavBarItemProps {
  toggleMenu: () => void;
  root?: boolean;
  level: number;
  color: string;
  name: string;
  subItems?: NavBarItemObj[];
  openByDefault?: boolean;
  className?: string;
  icon?: any;
}

const StyledLink = styled(Link as any)`
  text-decoration: none;
  color: unset;
`;
const StyledSubItem = styled('div' as any)`
  position: relative;
  & > * > svg {
    width: 0.875em;
    height: 0.875em;
  }
  & > [role='tooltip'] {
    top: 0;
    left: 100%;
    z-index: 10;
  }
`;

const StyledList = styled(List)`
  max-height: 50vh;
  overflow: auto;
`;

const StyledBetaTypography = styled(Typography)`
  background-color: ${teal2};
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
  color: white;
  font-weight: 600;
  text-align: center;
`;

@observer
export default class NavBarItem extends Component<NavBarItemProps> {
  @observable
  private open: boolean = this.props.openByDefault || false;

  @observable
  private anchorEl: HTMLElement | null = null;

  @observable
  private activeId: string = '';

  @action
  private setActiveEl = (id: string) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.anchorEl = e.currentTarget;
    this.activeId = id;
  };

  @action
  private removeActiveEl = () => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.anchorEl = null;
    this.activeId = '';
  };

  private handleClick = action((e: ClickEvent) => {
    this.open = !this.open;
  });

  private renderMenu = () => {
    const { subItems, toggleMenu } = this.props;
    const activeItem = this.activeId ? subItems?.find((item) => item.id === this.activeId) : null;
    return (
      <Popper
        open={!!this.anchorEl}
        style={{ zIndex: 2000 }}
        anchorEl={this.anchorEl}
        placement="right-start"
        container={document.querySelector('#tooltips-container')}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={this.removeActiveEl}>
                <List component={'div' as 'ul'}>
                  {activeItem?.subItems?.map((sI) => (
                    <StyledLink key={sI.name} to={sI.link} onClick={toggleMenu}>
                      <ListItem key={sI.name} button role={undefined} dense={true}>
                        <ListItemText
                          primary={
                            <Grid container direction="row" alignItems="baseline">
                              <Typography noWrap>
                                <Trans i18nKey={sI.name} />
                              </Typography>
                              {dashboardConfigurations.isBetaDashboard(activeItem.id as Dashboards) ? (
                                <StyledBetaTypography>B</StyledBetaTypography>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          }
                        />
                      </ListItem>
                    </StyledLink>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };
  public render() {
    const { name, subItems, root, className, icon } = this.props;
    return (
      <>
        <ListItem key={name} button role={undefined} dense={!root} onClick={this.handleClick} className={className}>
          <ListItemText
            disableTypography
            primary={
              <Typography style={{ color: '#ffffff' }}>
                {' '}
                <Trans i18nKey={name} />{' '}
              </Typography>
            }
          />
          {icon && (
            <ListItemSecondaryAction>
              <IconButton color="secondary" size="large">
                {icon}
              </IconButton>
            </ListItemSecondaryAction>
          )}
          {subItems && subItems.length > 0 && (this.open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {subItems && (
          <Collapse in={this.open} timeout="auto">
            <div onMouseLeave={this.removeActiveEl()}>
              {subItems.length > 0 && <StyledList>{subItems.map((item) => this.renderListItems(item))}</StyledList>}
              {this.renderMenu()}
            </div>
          </Collapse>
        )}
      </>
    );
  }

  private renderListItems = (item: NavBarItemObj) => {
    const { level, color, toggleMenu } = this.props;
    const title = <Trans i18nKey="common:navigation.disabled" />;

    if (item.disabled) {
      return (
        <Tooltip title={title}>
          <StyledSubItem key={item.name}>
            <StyledLink to={item.link} disabled={item.disabled} onClick={toggleMenu}>
              <ListItem disabled={item.disabled} button role={undefined} dense={true}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography style={{ color: '#ffffff' }} className={item.className}>
                      <Trans i18nKey={item.name} />
                    </Typography>
                  }
                />
                <Info />
              </ListItem>
            </StyledLink>
          </StyledSubItem>
        </Tooltip>
      );
    }

    if (item.subItems && item.subItems.length) {
      return (
        <StyledSubItem key={item.name} onMouseEnter={this.setActiveEl(item.id)}>
          <StyledLink to={item.link} onClick={toggleMenu}>
            <ListItem disabled={item.disabled} button role={undefined} dense={true}>
              <ListItemText
                disableTypography
                primary={
                  <Typography style={{ color: '#ffffff' }} className={item.className}>
                    <Trans i18nKey={item.name} />
                  </Typography>
                }
              />
              {item.disabled && (
                <span>
                  <Tooltip title="Disabled">
                    <Info />
                  </Tooltip>
                </span>
              )}
              {!item.disabled && (this.activeId === item.id ? <ChevronLeft /> : <ChevronRight />)}
            </ListItem>
          </StyledLink>
        </StyledSubItem>
      );
    }

    return (
      <StyledLink key={item.id} to={item.link} onClick={toggleMenu}>
        <NavBarItem
          toggleMenu={toggleMenu}
          level={level + 1}
          key={item.name}
          name={item.name}
          subItems={[]}
          color={color}
          className={item.className}
        />
      </StyledLink>
    );
  };
}
