import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, InputBase, List, ListItem, ListItemText, ThemeProvider } from '@mui/material';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import i18n from '../../../../i18n';
import { ApiEmployeeSearchResponseItem } from '../../api/api-interfaces';
import { rootStore } from '../../store/root-store';
import { defaultTheme } from '../../theme/default-theme';
import { lightInputTheme } from '../../theme/mui-theme';
import { formatUserSearchBasedOnDisplayNameAndLocale } from '../../utilFunctions/formatters';
import { styled } from '../styled';
import PopUpMenu from '../utils/PopUpMenu';

const SearchWrapper = styled('div')`
  position: relative;
  height: 25px;
  border-radius: 4px;
  background-color: ${defaultTheme.color.light}26;
  &:hover {
    background-color: ${defaultTheme.color.light}40;
  }
  margin: 0 8px;
  width: auto;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchInput = styled(InputBase)`
  width: 100%;
  height: 25px !important;
  font-size: 0.8em;

  & .input {
    padding: 8px 8px 8px 32px;
    transition: all 0.2s;
    width: 0;
    height: 25px;

    &:focus {
      padding-left: 40px;
      width: 200px;
    }
  }
` as typeof InputBase;

const StyledSearchIcon = styled(SearchIcon)`
  && {
    width: 0.8em;
    height: 0.8em;
  }
` as typeof SearchIcon;

const StyledPersonIcon = styled(PersonIcon)`
  && {
    width: 0.8em;
    height: 0.8em;
  }
` as typeof PersonIcon;

@observer
export class Search extends Component {
  @observable
  private anchorEl: HTMLElement | null = null;

  private searchWrapperRef = React.createRef<HTMLDivElement>();

  private timeout: NodeJS.Timeout | null = null;

  private handleClose = action(() => {
    this.anchorEl = null;
  });

  private handleClick = action((employeeId: string) => {
    rootStore.employeeProfileStore.handleClick(employeeId);
    this.handleClose();
  });

  private handleInputClick = action(() => {
    if (this.anchorEl) {
      this.anchorEl = null;
    } else {
      setTimeout(() => {
        this.anchorEl = this.searchWrapperRef.current;
      }, 100);
    }
  });

  public render() {
    const { employeeHits, state } = rootStore.employeeDataStore;
    const isSearching = state === 'PENDING';
    return (
      <>
        <ThemeProvider theme={lightInputTheme}>
          <SearchWrapper ref={this.searchWrapperRef}>
            <IconWrapper>
              {isSearching ? (
                <CircularProgress size={16} />
              ) : (
                <>
                  <StyledSearchIcon />
                  <StyledPersonIcon />
                </>
              )}
            </IconWrapper>
            <SearchInput
              placeholder={i18n.t('common:shell.searchEmployee')}
              classes={{ input: 'input' }}
              onClick={this.handleInputClick}
              onChange={this.handleChange}
              className={'intercom_appbar_search'}
            />
          </SearchWrapper>
        </ThemeProvider>
        <PopUpMenu
          anchorEl={this.anchorEl}
          open={Boolean(this.anchorEl && employeeHits?.length)}
          onClose={this.handleClose}
          placement={'bottom-end'}
        >
          <List>
            {employeeHits &&
              employeeHits.map((emp) => {
                // If local name exists, showing local name first
                return (
                  <ListItem key={emp.employeeId} onClick={() => this.handleClick(emp.employeeId)} button>
                    <ListItemText {...this.getNameFromEmployeeHit(emp)} />
                  </ListItem>
                );
              })}
          </List>
        </PopUpMenu>
      </>
    );
  }

  private getNameFromEmployeeHit = (emp: ApiEmployeeSearchResponseItem) => {
    const { selectedLanguage } = rootStore.languageStore;
    return formatUserSearchBasedOnDisplayNameAndLocale(emp, selectedLanguage.id);
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const { value } = event.target;

    this.timeout = setTimeout(() => {
      rootStore.employeeDataStore.searchForEmployees(value);
    }, 500);
  };
}
