import {
  ApiMasterDataAdvancedQuery,
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataQueryFilterItem,
} from '../api/api-interfaces';
import { BenchmarkConfig } from '../api/master-data-service';
import { DataTypes, EmployeeDataFields, Operations } from '../constants/constants';
import { Granularity } from '../date-manager/date-manager-constants';
import { getVersionsFilter, PAST_PRESENT_TEMPORALITY_FILTER } from '../filter/common-filters';
import { TimeSliderConfig } from '../filter/filter-store';
import { getDatesInRange } from '../filter/utils';
import {
  GetResponseForAdvancedMasterQueryService,
  GetResponseForAdvancedQueryServiceOptions,
} from './GetResponseForAdvancedQueryService';

interface GetDimensionValuesAtVersionIdServiceInputs {
  dimension: ApiMasterDataAdvancedQueryDimension;
  timeConfig?: TimeSliderConfig;
  benchmarkConfig?: BenchmarkConfig;
  options?: GetResponseForAdvancedQueryServiceOptions;
}

export const GetDimensionValuesForTimeConfigService = async (inputs: GetDimensionValuesAtVersionIdServiceInputs) => {
  const { dimension, timeConfig, benchmarkConfig, options } = inputs;
  const limitedToPermittedPopulation =
    benchmarkConfig && benchmarkConfig.limitedToPermittedPopulation === false ? false : true;
  const { dataType } = dimension;
  const getDimensionValuesQuery: ApiMasterDataAdvancedQuery = {
    dimensions: [dimension],
    limitedToPermittedPopulation,
    dataType: dataType as DataTypes,
  };
  if (timeConfig) {
    const dates = getDatesInRange({ ...timeConfig, granularity: Granularity.MONTH });
    getDimensionValuesQuery.filterItems = [getVersionsFilter(dates, Operations.EQUAL, dataType as DataTypes)];
    if (dataType === DataTypes.EMPLOYEE) {
      const termDateFilter: ApiMasterDataQueryFilterItem = {
        dataType: DataTypes.EMPLOYEE,
        property: EmployeeDataFields.TERM_DATE_NORMALIZED,
        operation: Operations.GREATER_THAN_OR_EQUAL_TO,
        values: [timeConfig.startDate],
      };
      getDimensionValuesQuery.filterItems.push(PAST_PRESENT_TEMPORALITY_FILTER, termDateFilter);
    }
  }
  const response = await GetResponseForAdvancedMasterQueryService(getDimensionValuesQuery, options);
  const dimensionValues: string[] = ((response && response.dataPoints) || []).map(
    (dp) => dp.dimensions[0].value ?? null
  );
  return dimensionValues;
};
