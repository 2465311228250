import StopCircleIcon from '@mui/icons-material/StopCircle';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Button, Grid, IconButton, keyframes, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClickEvent } from '../../../../common-types';
import { sentryReplay } from '../../sentry/SentryReplay';
import { FixedHeightGap, FixedWidthGap } from '../layout';
import { styled } from '../styled';
import { CopyButton } from '../utils/CopyButton';
import { NotistackSnackbarCloseAction } from '../utils/NotistackSnackbarCloseAction';
import PopUpMenu from '../utils/PopUpMenu';

const blinkAnimation = keyframes`
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
`;

const StyledStopCircleIcon = styled(StopCircleIcon)`
  animation: ${blinkAnimation} 2s linear infinite;
`;

export const SessionRecording = observer(() => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isRecordingMenuOpen, setIsRecordingMenuOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const { t } = useTranslation();
  const handleRecordingMenuOpen = (event: ClickEvent<Element>) => {
    setAnchorEl(event.currentTarget);
    setIsRecordingMenuOpen(true);
  };

  const startRecording = useCallback(() => {
    setIsRecordingMenuOpen(false);
    setIsRecording(true);
    sentryReplay.start();
    console.debug(`Sentry recording has started. Name: ${sentryReplay.name}, ID: ${sentryReplay.getReplayId()}`);
  }, []);

  const stopRecording = useCallback(async () => {
    setIsRecording(false);
    const replayId = sentryReplay.getReplayId();
    const link: string = `https://panalyt.sentry.io/replays/${replayId}`;
    try {
      sentryReplay.stop();
      console.debug(`Sentry recording finished. ID: ${replayId}`);
      enqueueSnackbar(
        <Grid container alignItems={'center'}>
          <Typography>{`${t('common:sessionRecording.recordingSuccessful')}:`}</Typography>
          <FixedWidthGap gap="8px" />
          <a href={link} target="_blank">
            <Typography>{link}</Typography>
          </a>
          <FixedWidthGap gap="8px" />
          <CopyButton color="white" copyText={link} />
        </Grid>,
        {
          variant: 'success',
          autoHideDuration: 60000,
          action: NotistackSnackbarCloseAction,
        }
      );
    } catch (e) {
      enqueueSnackbar(
        <Grid container alignItems={'center'}>
          <Typography>{`${t('common:sessionRecording.somethingWentWrong')} ${e}`}</Typography>
        </Grid>,
        {
          variant: 'error',
          autoHideDuration: 60000,
          action: NotistackSnackbarCloseAction,
        }
      );
    }
  }, []);

  return (
    <>
      <IconButton
        sx={{ padding: '5px', color: 'white' }}
        onClick={(e) => (isRecording ? stopRecording() : handleRecordingMenuOpen(e))}
      >
        {isRecording ? <StyledStopCircleIcon /> : <VideocamIcon />}
      </IconButton>
      <PopUpMenu
        open={isRecordingMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsRecordingMenuOpen(false)}
        placement="bottom-end"
      >
        <Grid container direction={'column'} padding={2}>
          <Grid item container>
            <Typography variant="body1" color="textSecondary">
              {<Trans i18nKey="common:sessionRecording.recordingInstructions" components={[<br />]} />}
            </Typography>
          </Grid>
          <FixedHeightGap gap="16px" />
          <Grid item container justifyContent={'center'}>
            <Button variant="outlined" color="secondary" startIcon={<VideocamIcon />} onClick={startRecording}>
              {t('common:sessionRecording.startRecording')}
            </Button>
          </Grid>
        </Grid>
      </PopUpMenu>
    </>
  );
});
