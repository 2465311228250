import { DataFields } from '../../constants/constants';
import { baseAuthedTracker } from './baseTracker';

const DASHBOARD_DOWNLOADED_EVENT = '[Dashboard] Downloaded';
const DASHBOARD_DATA_VIEW_EXPORT_EVENT = '[Dashboard] Data View Exported';
const FULL_SCREEN_EVENT = '[Dashboard] Full Screen';
const SORT_COLUMNS_EVENT = '[Dashboard] Sort Data View Columns';
const SORT_BY_EVENT = '[Dashboard] Sort By Column Data View';
const CHOOSE_COLUMNS_EVENT = '[Dashboard] Choose Data View Columns';

export const trackDashboardDownload = (type: 'pdf' | 'image') => {
  baseAuthedTracker(DASHBOARD_DOWNLOADED_EVENT, {
    type,
  });
};

export const trackFullScreen = () => {
  baseAuthedTracker(FULL_SCREEN_EVENT);
};

export const trackDashboardDataViewExport = (type: 'csv' | 'xlsx') => {
  baseAuthedTracker(DASHBOARD_DATA_VIEW_EXPORT_EVENT, {
    type,
  });
};

export const trackColumnsSort = () => {
  baseAuthedTracker(SORT_COLUMNS_EVENT);
};

export const trackDataViewSortBy = (sortBy: DataFields) => {
  baseAuthedTracker(SORT_BY_EVENT, { sortBy });
};

export const trackChooseColumns = (columnSelector: 'old' | 'new') => {
  baseAuthedTracker(CHOOSE_COLUMNS_EVENT);
};
