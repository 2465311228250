import LogoutIcon from '@mui/icons-material/Logout';
import { Grid, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router';
import whiteLogoUrl from '../../../assets/image/panalyt_logo_white.png';
import { ApiUser } from '../../common/api/api-interfaces';
import { UserRolePreferences } from '../../common/api/zod-schemas';
import { prefetchData } from '../../common/components/filter-tray/filter-tray-data/dataHooks';
import { FixedWidthGap } from '../../common/components/layout';
import { PrivateRoute } from '../../common/components/routing/private-route';
import LanguageSwitcher from '../../common/components/shell/LanguageSwitcher';
import { Header, Logo, StyledAppBar } from '../../common/components/shell/Shell';
import LoadingOverlay from '../../common/components/utils/LoadingOverlay';
import { DataTypes, Domains } from '../../common/constants/constants';
import { dateManagerService } from '../../common/date-manager/date-manager-service';
import { dataUploadRoute } from '../../common/routes';
import { rootStore } from '../../common/store/root-store';

const preLoadDashboards = async () => {
  if (!rootStore.dashboardStore.dashboards.length) {
    return rootStore.dashboardStore.getAllDashboards();
  }
};
const preLoadNonNullFields = async () => {
  return rootStore.employeeDataStore.loadNonNullFields();
};

const preLoadAliases = async (domain: Domains) => {
  if (!rootStore.aliasStore.aliasesLoaded) {
    return rootStore.aliasStore.loadAliases(domain);
  }
};

const loadOldestStartDate = () => {
  if (!rootStore.employeeDataStore.oldestStartDate) {
    rootStore.employeeDataStore.loadOldestStartDate();
  }
};

const preLoadFilters = async () => {
  const filters = rootStore.filterTrayStore.getNonEmptyFilters([DataTypes.EMPLOYEE]);
  filters
    .filter((f) => !f.noDefaultSubItems)
    .forEach((f) => requestIdleCallback(() => prefetchData(f.dimension, rootStore.timeSliderStore.timeSliderConfig)));
};

const getSelectedCompany = () => {
  if (!rootStore.companyStore.selectedCompanyFetched) {
    rootStore.companyStore.getSelectedCompany();
  }
};

const loadUserRolePreference = async () => {
  if (!rootStore.userStore.userRolePreferencesFetched) {
    return rootStore.userStore.loadUserRolePreference();
  }
  return null;
};

const loadUserInfo = async () => {
  if (!rootStore.userStore.currentUserFetched) {
    return rootStore.userStore.loadUserInfo();
  }
  return null;
};

const loadWindowIntercomSettings = (userRolePreferences: UserRolePreferences | null, userInfo: ApiUser | null) => {
  rootStore.intercomStore.applyWindowIntercomSettings(userRolePreferences, userInfo?.email ?? null);
};

const loadBlockingDependencies = async () => {
  rootStore.metricStore.loadAllMetricGroupDetails();
  const [userRolePreferences, currentUser] = await Promise.all([loadUserRolePreference(), loadUserInfo()]);
  loadWindowIntercomSettings(userRolePreferences, currentUser);
  await Promise.allSettled([preLoadDashboards(), loadOldestStartDate(), getSelectedCompany()]);
};

const preLoadNonBlockingDependencies = async () => {
  await rootStore.timeSliderStore.setUpTimeSlider();
  preLoadNonNullFields().then(() => preLoadFilters());
  preLoadAliases(rootStore.companyStore.domain as Domains);
};

export const areBlockingDependenciesReady = () => {
  const { selectedCompanyFetched } = rootStore.companyStore;
  const { userRolePreferencesFetched } = rootStore.userStore;
  const { dashboardsFetched } = rootStore.dashboardStore;
  const oldestStartDateFetched =
    rootStore.employeeDataStore.oldestStartDate || rootStore.employeeDataStore.oldestStartDateNotFound;
  const { executorRoleReady } = rootStore.permissionsStore;
  const { dateFormatManagerReady } = dateManagerService;
  const allMetricGroupDetailsLoaded = rootStore.metricStore.allMetricGroupDetailsLoaded;
  const blockingDependenciesReady =
    selectedCompanyFetched &&
    userRolePreferencesFetched &&
    dashboardsFetched &&
    executorRoleReady() &&
    oldestStartDateFetched &&
    dateFormatManagerReady &&
    allMetricGroupDetailsLoaded;
  return blockingDependenciesReady;
};

const DataUploadOnlyApp = () => {
  return (
    <Grid container>
      <StyledAppBar>
        <Grid sx={{ height: '100%', position: 'relative' }} container justifyContent={'center'} alignItems={'center'}>
          <Logo src={whiteLogoUrl} className="intercom_appbar_logo" />
          <Grid item container sx={{ position: 'absolute', right: 0 }} justifyContent={'flex-end'}>
            <LanguageSwitcher />
            <FixedWidthGap gap="8px" />
            <IconButton
              onClick={() => {
                localStorage.clear();
                rootStore.authStore.logOutUser();
              }}
              style={{ color: 'white' }}
            >
              <LogoutIcon />
            </IconButton>
          </Grid>
        </Grid>
      </StyledAppBar>
      <main>
        <Header />
        <Switch>
          <PrivateRoute key={dataUploadRoute.id} {...dataUploadRoute} />
          <Redirect from="/" to={dataUploadRoute.path} />
        </Switch>
      </main>
    </Grid>
  );
};
export const DependenciesGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const { executorRoleReady } = rootStore.permissionsStore;
  useEffect(() => {
    if (executorRoleReady()) {
      loadBlockingDependencies();
    }
  }, [executorRoleReady()]);

  const isDataUploadOnlyRole =
    rootStore.permissionsStore.executorRoleError && rootStore.permissionsStore.canUploadData();

  const { selectedLanguage } = rootStore.languageStore;
  const firstMonthOfYear = rootStore.timeSliderStore.timeSliderConfig.firstMonthOfYear;
  useEffect(() => {
    dateManagerService.createDateFormatManagers(selectedLanguage.id, firstMonthOfYear);
  }, [selectedLanguage, firstMonthOfYear]);

  const blockingDependenciesReady = areBlockingDependenciesReady();
  useEffect(() => {
    if (blockingDependenciesReady) {
      preLoadNonBlockingDependencies();
    }
  }, [blockingDependenciesReady]);

  if (isDataUploadOnlyRole) {
    return <DataUploadOnlyApp />;
  }
  if (!blockingDependenciesReady) {
    return <LoadingOverlay />;
  } else {
    return <>{children}</>;
  }
});
